import axios from "axios";

export class AssistantRecognizer {
    static async recognize(prompt) {
        if (prompt.indexOf('шахмат ку') !== -1) {
            prompt = prompt.replace('шахмат ку', 'шахматку')
        }

        try {
            const {data} = await axios.get('https://voice.itrielt.ru/command?query=' + prompt)
            return data;
        } catch (error) {
            console.error(error)
            return null
        }
    }
}