export class DefineFilterCommand {
    #_intent = {};

    constructor(context) {
        this.#_intent = context.intent
    }

    execute(context) {
        const self = context.object

        try {
            self.$router.push('/vip')
        } catch (err) {
        }

        setTimeout(() => {
            self.$store.dispatch('SET_VOICE_ASSISTANT_CONTEXT' , this.#_intent)
        }, 2500)
    }
}