<template>
  <div v-if="(($route.name == 'vip') || ($root.site.domain === 'vip')) && isBrowserSupport" :class="'voice-assistant ' + (showFull ? 'w-100' : '')">
    <div v-if="showFull" class="close-assistant-button" @click="hideBlock"></div>
    <p v-if="showFull && answer && transcript">{{ transcript }}</p>
    <h4 v-if="showFull">{{ answer ?? (transcript ? transcript : 'Чем могу помочь?') }}</h4>
    <div class="round-voice-indicator" @click="handleVoiceClick">
      <svg width="50" height="50" viewBox="0 0 84 84" fill="transparent" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1650_1093)">
          <g filter="url(#filter0_f_1650_1093)" :class="STATUSES_CLASSES[status]">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.82989 39.0281C3.59983 49.4988 7.6123 60.2241 15.8024 68.4142C23.9924 76.6042 34.7175 80.6167 45.1881 80.3868C34.3608 81.2531 23.2363 77.5451 14.9539 69.2627C6.67137 60.9802 2.96337 49.8555 3.82989 39.0281Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M31.5213 78.3268C44.3077 82.0015 58.6521 78.8021 68.7255 68.7287C78.799 58.6553 81.9983 44.3108 78.3235 31.5244C81.3537 44.0009 77.843 57.9142 67.877 67.8802C57.911 77.8462 43.9978 81.3568 31.5213 78.3268Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M78.0854 51.049C74.444 63.1427 64.6383 73.2033 51.3144 76.7734C38.0324 80.3323 24.5531 76.5575 15.3546 67.9621C15.4664 68.0768 15.579 68.1909 15.6926 68.3044C30.2201 82.8319 53.7738 82.8319 68.3013 68.3044C73.245 63.3607 76.5063 57.3718 78.0854 51.049Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M71.6544 21.5819C75.8873 28.7406 77.8493 37.4712 76.6557 46.5543C75.4653 55.613 71.3334 63.5223 65.4202 69.34C66.1157 68.7428 66.7939 68.1147 67.4529 67.4558C79.9342 54.9744 81.3347 35.6083 71.6544 21.5819Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M73.0444 24.9743C63.1313 7.98966 41.4795 2.11543 24.5971 11.8625C7.7248 21.6037 1.98291 43.2719 11.7113 60.3444C3.44726 46.7251 5.19868 28.7354 16.9655 16.9686C30.7901 3.14402 53.2041 3.14402 67.0287 16.9686C69.4623 19.4022 71.4675 22.102 73.0444 24.9743Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M45.049 4.92472C37.1298 4.93859 28.9694 7.31875 21.7386 12.2411C14.4843 17.1795 9.26756 23.9265 6.35645 31.3171C8.06484 25.5993 11.1769 20.2114 15.6926 15.6957C23.7333 7.65506 34.539 4.06473 45.049 4.92472Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M72.0274 25.6163C74.2763 30.4883 75.5568 35.97 75.5962 41.7723C75.6355 47.5752 74.4292 53.0744 72.246 57.9769C77.5859 47.8748 77.513 35.6607 72.0274 25.6163Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.6048 12.66C22.9651 14.1366 20.4824 15.997 18.2382 18.2412C5.11655 31.3628 5.11655 52.6372 18.2382 65.7588C31.3598 78.8804 52.6341 78.8804 65.7557 65.7588C66.3459 65.1687 66.9095 64.5621 67.4465 63.9404C66.0244 65.5474 64.4257 67.0397 62.6544 68.3893C47.9641 79.5815 26.958 76.714 15.7358 61.9845C4.51365 47.255 7.32506 26.2413 22.0153 15.049C23.1751 14.1654 24.3742 13.3695 25.6048 12.66Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M13.2298 27.0744C11.4264 30.6982 10.2778 34.7249 9.95052 39.0113C8.59413 56.7772 21.8607 72.2761 39.5822 73.6291C57.3036 74.9821 72.7693 61.6768 74.1257 43.911C74.4504 39.6586 73.9373 35.536 72.7258 31.7063C76.4948 42.9865 73.8886 55.9289 64.9072 64.9103C52.2542 77.5633 31.7397 77.5633 19.0867 64.9103C8.84584 54.6694 6.89355 39.2787 13.2298 27.0744Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M34.6339 12.2944C29.4033 13.5857 24.447 16.2751 20.3595 20.3626C8.40949 32.3126 8.40949 51.6875 20.3595 63.6375C22.4436 65.7216 24.7535 67.4422 27.2104 68.7993C16.8294 62.7714 10.5515 50.792 12.2625 38.0175C13.9661 25.2981 23.1032 15.4233 34.6339 12.2944Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M55.699 12.9168C39.2282 5.15692 19.6132 12.1594 11.8877 28.5574C11.6494 29.0632 11.4251 29.5721 11.2148 30.0836C12.8229 25.9229 15.3055 22.0224 18.6626 18.6653C31.5499 5.77802 52.4444 5.77803 65.3317 18.6653C76.0301 29.3638 77.8472 45.5804 70.7829 58.1534C71.0443 57.6641 71.2947 57.1657 71.5337 56.6583C79.2593 40.2604 72.1698 20.6766 55.699 12.9168Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M60.2883 65.7829C56.0985 68.7581 51.0887 70.7737 45.5736 71.4437C40.0145 72.1189 34.6264 71.3439 29.817 69.4251C39.6941 73.8091 51.4541 72.595 60.2883 65.7829Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M69.215 29.7108C62.0855 14.6244 44.1453 8.14128 29.1443 15.2304C18.9095 20.0671 12.6872 29.9721 12.0309 40.5694C12.3722 33.3754 15.2898 26.2807 20.7837 20.7868C32.4995 9.07107 51.4944 9.07107 63.2101 20.7868C67.5976 25.1742 70.342 30.5825 71.4433 36.2471C70.9616 34.0365 70.2235 31.8447 69.215 29.7108Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M32.7151 14.73C47.4591 10.234 63.2253 18.4111 68.1797 33.2699C73.133 48.1255 65.4337 64.1244 50.9481 69.3802C55.1213 68.0196 59.0452 65.6812 62.3615 62.3648C73.6086 51.1177 73.6086 32.8826 62.3615 21.6355C54.343 13.617 42.7728 11.3152 32.7151 14.73Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M49.1296 69.9075C42.5512 70.831 35.6325 69.7914 29.2869 66.4993C22.9337 63.2033 18.0956 58.1374 15.0631 52.2184C16.4641 55.9175 18.6538 59.3862 21.6324 62.3647C29.0852 69.8176 39.6064 72.3318 49.1296 69.9075Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M36.9684 10.5977C33.7573 11.4625 30.6047 12.7553 27.5881 14.4969C20.7491 18.4455 15.5584 24.1526 12.2574 30.7174C13.8043 26.6328 16.2221 22.8028 19.511 19.514C24.4371 14.5878 30.5773 11.6158 36.9684 10.5977Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M72.544 15.6956C56.142 -0.706371 29.549 -0.706369 13.147 15.6957C2.76693 26.0757 -1.04407 40.5373 1.71398 53.9144C-2.4946 39.6384 1.03357 23.5665 12.2985 12.3015C28.7005 -4.10048 55.2934 -4.10049 71.6954 12.3015C77.7174 18.3235 81.5284 25.7191 83.1285 33.4798C81.2092 26.9695 77.6811 20.8328 72.544 15.6956Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.68273 59.5019C17.2807 79.5902 42.9675 86.473 63.0558 74.875C75.7687 67.5352 83.1928 54.5528 83.991 40.9176C84.3613 55.7965 76.7936 70.4076 62.9969 78.3731C42.9087 89.9711 17.2219 83.0883 5.62388 63C1.36572 55.6247 -0.401296 47.4947 0.0617624 39.5843C0.230615 46.3695 2.05025 53.2103 5.68273 59.5019Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M75.3112 64.6981C86.9092 44.6098 80.0264 18.923 59.9381 7.32502C47.2252 -0.0147991 32.27 0.0469788 20.0626 6.17331C32.7629 -1.58679 49.2003 -2.33855 62.997 5.62695C83.0853 17.2249 89.968 42.9117 78.37 63C74.1119 70.3754 67.9546 75.9706 60.8725 79.5248C66.6642 75.986 71.6787 70.9897 75.3112 64.6981Z"
                  fill="#6F77FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.36238 46.3448C7.50992 68.3279 28.693 82.7865 50.6762 78.639C64.5883 76.0142 75.4868 66.5666 80.563 54.4112C76.1535 68.0713 64.6048 78.9555 49.5067 81.804C27.5236 85.9515 6.34045 71.4929 2.19291 49.5098C0.670162 41.4388 1.65544 33.4756 4.60033 26.4237C2.58952 32.6531 2.06338 39.4597 3.36238 46.3448Z"
                  fill="#6F77FE"/>
          </g>
          <g clip-path="url(#clip1_1650_1093)" :class="STATUSES_CLASSES[status]">
            <g>
              <path
                  d="M3.82978 39.028C3.59973 49.4987 7.6122 60.224 15.8023 68.4141C23.9923 76.6041 34.7174 80.6166 45.1881 80.3867C34.3608 81.2531 23.2362 77.5451 14.9538 69.2626C6.67127 60.9801 2.96327 49.8554 3.82978 39.028Z"
                  fill="#6F77FE"/>
              <path opacity="0.4"
                    d="M31.5211 78.3266C44.3076 82.0014 58.6521 78.8021 68.7255 68.7286C78.799 58.6551 81.9983 44.3107 78.3235 31.5242C81.3537 44.0008 77.843 57.9141 67.877 67.8801C57.911 77.8461 43.9977 81.3568 31.5211 78.3266Z"
                    fill="#6F77FE"/>
              <path
                  d="M78.0853 51.0489C74.444 63.1426 64.6382 73.2032 51.3144 76.7733C38.0323 80.3322 24.553 76.5574 15.3546 67.9621C15.4663 68.0767 15.579 68.1908 15.6925 68.3043C30.22 82.8318 53.7738 82.8319 68.3013 68.3043C73.2449 63.3607 76.5063 57.3717 78.0853 51.0489Z"
                  fill="#6F77FE"/>
              <path
                  d="M71.6543 21.582C75.8873 28.7406 77.8492 37.4713 76.6556 46.5543C75.4653 55.613 71.3334 63.5223 65.4202 69.34C66.1157 68.7428 66.7938 68.1148 67.4528 67.4558C79.9341 54.9745 81.3346 35.6084 71.6543 21.582Z"
                  fill="#6F77FE"/>
              <path opacity="0.4"
                    d="M73.0444 24.9744C63.1314 7.98954 41.4794 2.1152 24.597 11.8623C7.72464 21.6035 1.98275 43.2717 11.7111 60.3442C3.44709 46.7249 5.19851 28.7353 16.9654 16.9684C30.7899 3.14383 53.204 3.14383 67.0285 16.9684C69.4623 19.4021 71.4675 22.102 73.0444 24.9744Z"
                    fill="#6F77FE"/>
              <path
                  d="M45.049 4.92461C37.1298 4.93846 28.9694 7.3186 21.7385 12.241C14.4842 17.1794 9.26745 23.9264 6.35635 31.317C8.06474 25.5992 11.1768 20.2113 15.6925 15.6956C23.7332 7.65491 34.539 4.06458 45.049 4.92461Z"
                  fill="#6F77FE"/>
              <path opacity="0.4"
                    d="M72.0274 25.6163C74.2763 30.4883 75.5568 35.97 75.5961 41.7722C75.6355 47.5752 74.4291 53.0744 72.2459 57.9768C77.5858 47.8748 77.513 35.6607 72.0274 25.6163Z"
                    fill="#6F77FE"/>
              <path
                  d="M25.6043 12.6602C22.9648 14.1367 20.4822 15.9971 18.2381 18.2412C5.11649 31.3628 5.11649 52.6371 18.2381 65.7588C31.3597 78.8804 52.6341 78.8804 65.7557 65.7588C66.3459 65.1685 66.9096 64.5618 67.4467 63.9401C66.0245 65.5471 64.4258 67.0396 62.6543 68.3892C47.9641 79.5815 26.9579 76.714 15.7358 61.9845C4.51359 47.255 7.325 26.2412 22.0152 15.049C23.1749 14.1655 24.3739 13.3696 25.6043 12.6602Z"
                  fill="#6F77FE"/>
              <path
                  d="M13.2295 27.0747C11.4262 30.6985 10.2777 34.7251 9.95046 39.0113C8.59407 56.7771 21.8606 72.276 39.5821 73.629C57.3036 74.982 72.7693 61.6768 74.1256 43.9109C74.4503 39.6586 73.9372 35.5362 72.7258 31.7066C76.4947 42.9867 73.8884 55.9289 64.9071 64.9102C52.2542 77.5632 31.7396 77.5632 19.0866 64.9102C8.8459 54.6695 6.89353 39.279 13.2295 27.0747Z"
                  fill="#6F77FE"/>
              <path opacity="0.3"
                    d="M34.6338 12.2944C29.4032 13.5856 24.447 16.275 20.3595 20.3625C8.40941 32.3125 8.40941 51.6874 20.3595 63.6374C22.4435 65.7215 24.7534 67.4421 27.2104 68.7993C16.8293 62.7714 10.5515 50.7919 12.2624 38.0174C13.966 25.298 23.1031 15.4233 34.6338 12.2944Z"
                    fill="#6F77FE"/>
              <path
                  d="M55.6987 12.917C39.2279 5.15713 19.6129 12.1597 11.8874 28.5576C11.6492 29.0633 11.425 29.572 11.2148 30.0832C12.8229 25.9227 15.3054 22.0225 18.6623 18.6656C31.5496 5.77826 52.4441 5.77826 65.3314 18.6656C76.0298 29.364 77.8469 45.5807 70.7826 58.1536C71.044 57.6644 71.2944 57.166 71.5334 56.6585C79.259 40.2606 72.1695 20.6769 55.6987 12.917Z"
                  fill="#6F77FE"/>
              <path opacity="0.2"
                    d="M60.2883 65.7829C56.0985 68.7581 51.0886 70.7737 45.5736 71.4436C40.0144 72.1189 34.6263 71.3438 29.817 69.425C39.6941 73.809 51.454 72.595 60.2883 65.7829Z"
                    fill="#6F77FE"/>
              <path
                  d="M69.215 29.7107C62.0855 14.6243 44.1452 8.14118 29.1442 15.2303C18.9094 20.067 12.6871 29.9721 12.0308 40.5694C12.3721 33.3753 15.2897 26.2806 20.7837 20.7867C32.4994 9.07095 51.4943 9.07095 63.2101 20.7867C67.5975 25.1741 70.3419 30.5824 71.4432 36.247C70.9616 34.0364 70.2234 31.8446 69.215 29.7107Z"
                  fill="#6F77FE"/>
              <path opacity="0.2"
                    d="M32.7157 14.7297C47.4595 10.234 63.2254 18.411 68.1798 33.2698C73.1332 48.1254 65.4338 64.1244 50.9481 69.3801C55.1213 68.0195 59.0452 65.681 62.3616 62.3647C73.6087 51.1176 73.6087 32.8824 62.3616 21.6353C54.3433 13.617 42.7732 11.3151 32.7157 14.7297Z"
                    fill="#6F77FE"/>
              <path opacity="0.2"
                    d="M49.1295 69.9074C42.5511 70.8309 35.6324 69.7913 29.2867 66.4992C22.9335 63.2032 18.0955 58.1373 15.063 52.2183C16.4639 55.9174 18.6536 59.3861 21.6322 62.3647C29.085 69.8175 39.6063 72.3317 49.1295 69.9074Z"
                    fill="#6F77FE"/>
              <path
                  d="M36.9683 10.5978C33.7571 11.4625 30.6046 12.7554 27.5881 14.497C20.749 18.4455 15.5583 24.1526 12.2574 30.7173C13.8042 26.6328 16.2221 22.8028 19.5109 19.514C24.437 14.5879 30.5772 11.6158 36.9683 10.5978Z"
                  fill="#6F77FE"/>
              <path
                  d="M72.5439 15.6956C56.1419 -0.706427 29.549 -0.70643 13.1469 15.6956C2.76688 26.0757 -1.04413 40.5372 1.71392 53.9143C-2.49466 39.6383 1.0335 23.5664 12.2984 12.3015C28.7004 -4.10054 55.2934 -4.10054 71.6954 12.3015C77.7173 18.3234 81.5283 25.7191 83.1284 33.4797C81.2092 26.9695 77.681 20.8327 72.5439 15.6956Z"
                  fill="#6F77FE"/>
              <path
                  d="M8.6891 19.3055C-2.90888 39.3938 3.97388 65.0806 24.0622 76.6786C36.7751 84.0184 51.7303 83.9567 63.9377 77.8303C51.2374 85.5904 34.8 86.3422 21.0033 78.3767C0.915031 66.7787 -5.96772 41.0919 5.63026 21.0036C9.88842 13.6283 16.0457 8.033 23.1278 4.47884C17.3361 8.01765 12.3216 13.0139 8.6891 19.3055Z"
                  fill="#6F77FE"/>
              <path
                  d="M5.68267 59.5019C17.2806 79.5902 42.9674 86.4729 63.0557 74.8749C75.7687 67.5351 83.1928 54.5527 83.9909 40.9176C84.3612 55.7964 76.7935 70.4075 62.9969 78.373C42.9086 89.971 17.2218 83.0882 5.62382 63C1.36566 55.6246 -0.40135 47.4946 0.0617002 39.5843C0.230557 46.3694 2.05019 53.2102 5.68267 59.5019Z"
                  fill="#6F77FE"/>
              <path opacity="0.7"
                    d="M75.3112 64.698C86.9091 44.6098 80.0264 18.923 59.9381 7.32499C47.2252 -0.0148315 32.27 0.0469416 20.0625 6.17326C32.7629 -1.58682 49.2003 -2.33859 62.9969 5.62691C83.0852 17.2249 89.968 42.9117 78.37 63C74.1118 70.3753 67.9546 75.9706 60.8725 79.5248C66.6642 75.9859 71.6787 70.9897 75.3112 64.698Z"
                    fill="#6F77FE"/>
            </g>
          </g>
          <g clip-path="url(#clip2_1650_1093)">
            <g>
              <path
                  d="M3.8299 39.0281C3.59985 49.4989 7.61232 60.2241 15.8024 68.4142C23.9925 76.6043 34.7175 80.6167 45.1882 80.3868C34.3609 81.2532 23.2363 77.5452 14.9539 69.2628C6.67139 60.9803 2.96339 49.8555 3.8299 39.0281Z"
                  fill="#6F77FE"/>
              <path opacity="0.4"
                    d="M31.5213 78.3267C44.3077 82.0015 58.6522 78.8022 68.7257 68.7287C78.7991 58.6553 81.9985 44.3108 78.3237 31.5243C81.3538 44.0009 77.8431 57.9142 67.8771 67.8802C57.9111 77.8462 43.9978 81.3569 31.5213 78.3267Z"
                    fill="#6F77FE"/>
              <path
                  d="M78.0854 51.049C74.4441 63.1428 64.6383 73.2033 51.3145 76.7734C38.0325 80.3324 24.5531 76.5576 15.3547 67.9622C15.4664 68.0768 15.5791 68.1909 15.6926 68.3045C30.2201 82.832 53.7739 82.832 68.3014 68.3045C73.2451 63.3608 76.5064 57.3718 78.0854 51.049Z"
                  fill="#6F77FE"/>
              <path
                  d="M71.6544 21.5821C75.8874 28.7408 77.8493 37.4714 76.6557 46.5544C75.4654 55.6131 71.3335 63.5224 65.4203 69.3401C66.1158 68.7429 66.7939 68.1149 67.4529 67.4559C79.9342 54.9746 81.3348 35.6085 71.6544 21.5821Z"
                  fill="#6F77FE"/>
              <path opacity="0.4"
                    d="M73.0445 24.9745C63.1315 7.98966 41.4796 2.11532 24.5971 11.8624C7.72476 21.6037 1.98287 43.2718 11.7112 60.3444C3.44721 46.7251 5.19863 28.7354 16.9655 16.9685C30.7901 3.14396 53.2041 3.14395 67.0287 16.9685C69.4624 19.4022 71.4676 22.1021 73.0445 24.9745Z"
                    fill="#6F77FE"/>
              <path
                  d="M45.0491 4.92474C37.1299 4.93859 28.9695 7.31874 21.7387 12.2411C14.4844 17.1795 9.26758 23.9265 6.35647 31.3172C8.06486 25.5993 11.1769 20.2115 15.6927 15.6957C23.7333 7.65504 34.5391 4.06472 45.0491 4.92474Z"
                  fill="#6F77FE"/>
              <path opacity="0.4"
                    d="M72.0275 25.6164C74.2764 30.4884 75.5569 35.9701 75.5963 41.7724C75.6356 47.5753 74.4292 53.0745 72.246 57.977C77.5859 47.8749 77.5131 35.6608 72.0275 25.6164Z"
                    fill="#6F77FE"/>
              <path
                  d="M25.6045 12.6603C22.965 14.1368 20.4824 15.9972 18.2382 18.2413C5.11661 31.3629 5.11661 52.6373 18.2382 65.7589C31.3598 78.8805 52.6342 78.8805 65.7558 65.7589C66.346 65.1687 66.9097 64.562 67.4468 63.9402C66.0246 65.5473 64.4259 67.0397 62.6544 68.3894C47.9642 79.5816 26.9581 76.7141 15.7359 61.9846C4.51371 47.2551 7.32512 26.2414 22.0153 15.0491C23.175 14.1656 24.374 13.3697 25.6045 12.6603Z"
                  fill="#6F77FE"/>
              <path
                  d="M13.2297 27.0749C11.4263 30.6986 10.2778 34.7252 9.95059 39.0114C8.59419 56.7772 21.8607 72.2761 39.5822 73.6291C57.3037 74.9821 72.7694 61.6769 74.1258 43.9111C74.4504 39.6588 73.9373 35.5363 72.7259 31.7067C76.4948 42.9868 73.8886 55.929 64.9073 64.9103C52.2543 77.5633 31.7397 77.5633 19.0868 64.9103C8.84602 54.6696 6.89366 39.2792 13.2297 27.0749Z"
                  fill="#6F77FE"/>
              <path opacity="0.3"
                    d="M34.6339 12.2945C29.4033 13.5857 24.4471 16.2751 20.3596 20.3626C8.40953 32.3127 8.40953 51.6875 20.3596 63.6376C22.4437 65.7216 24.7536 67.4423 27.2105 68.7994C16.8295 62.7715 10.5516 50.792 12.2625 38.0175C13.9661 25.2982 23.1032 15.4234 34.6339 12.2945Z"
                    fill="#6F77FE"/>
              <path
                  d="M55.6988 12.9171C39.228 5.15725 19.613 12.1598 11.8875 28.5577C11.6493 29.0634 11.4251 29.5721 11.2149 30.0834C12.823 25.9229 15.3055 22.0226 18.6624 18.6657C31.5497 5.77838 52.4442 5.77838 65.3315 18.6657C76.03 29.3642 77.847 45.5808 70.7827 58.1538C71.0441 57.6645 71.2945 57.1661 71.5336 56.6587C79.2591 40.2607 72.1697 20.677 55.6988 12.9171Z"
                  fill="#6F77FE"/>
              <path opacity="0.2"
                    d="M60.2884 65.783C56.0986 68.7582 51.0887 70.7738 45.5737 71.4437C40.0146 72.119 34.6265 71.3439 29.8171 69.4251C39.6942 73.8092 51.4542 72.5951 60.2884 65.783Z"
                    fill="#6F77FE"/>
              <path
                  d="M69.2151 29.7108C62.0856 14.6244 44.1453 8.1413 29.1444 15.2304C18.9095 20.0672 12.6873 29.9722 12.0309 40.5695C12.3722 33.3754 15.2898 26.2807 20.7838 20.7868C32.4995 9.07107 51.4945 9.07107 63.2102 20.7868C67.5976 25.1742 70.342 30.5826 71.4433 36.2471C70.9617 34.0365 70.2235 31.8447 69.2151 29.7108Z"
                  fill="#6F77FE"/>
              <path opacity="0.2"
                    d="M32.7158 14.7298C47.4596 10.2341 63.2256 18.4112 68.1799 33.2699C73.1333 48.1255 65.4339 64.1245 50.9482 69.3802C55.1214 68.0196 59.0454 65.6811 62.3617 62.3648C73.6088 51.1177 73.6088 32.8825 62.3617 21.6354C54.3434 13.6171 42.7734 11.3152 32.7158 14.7298Z"
                    fill="#6F77FE"/>
              <path opacity="0.2"
                    d="M49.1296 69.9075C42.5512 70.831 35.6325 69.7914 29.2869 66.4993C22.9337 63.2033 18.0956 58.1374 15.0631 52.2184C16.464 55.9176 18.6538 59.3862 21.6323 62.3648C29.0852 69.8176 39.6065 72.3319 49.1296 69.9075Z"
                    fill="#6F77FE"/>
              <path
                  d="M36.9684 10.5979C33.7573 11.4627 30.6047 12.7555 27.5882 14.4971C20.7491 18.4456 15.5584 24.1527 12.2575 30.7175C13.8043 26.6329 16.2222 22.803 19.511 19.5141C24.4371 14.588 30.5773 11.616 36.9684 10.5979Z"
                  fill="#6F77FE"/>
              <path
                  d="M72.544 15.6957C56.142 -0.70629 29.5491 -0.706292 13.1471 15.6957C2.767 26.0758 -1.04401 40.5374 1.71404 53.9145C-2.49454 39.6384 1.03363 23.5665 12.2985 12.3016C28.7006 -4.1004 55.2935 -4.1004 71.6955 12.3016C77.7175 18.3236 81.5285 25.7192 83.1285 33.4798C81.2093 26.9696 77.6811 20.8328 72.544 15.6957Z"
                  fill="#6F77FE"/>
              <path
                  d="M8.68923 19.3057C-2.90876 39.394 3.974 65.0808 24.0623 76.6787C36.7752 84.0186 51.7304 83.9568 63.9378 77.8305C51.2375 85.5906 34.8001 86.3423 21.0034 78.3768C0.915153 66.7788 -5.9676 41.092 5.63038 21.0038C9.88854 13.6284 16.0458 8.03313 23.1279 4.47898C17.3362 8.01778 12.3217 13.014 8.68923 19.3057Z"
                  fill="#6F77FE"/>
              <path
                  d="M5.68279 59.502C17.2808 79.5903 42.9676 86.473 63.0559 74.8751C75.7688 67.5352 83.1929 54.5528 83.991 40.9177C84.3613 55.7965 76.7937 70.4076 62.997 78.3731C42.9087 89.9711 17.2219 83.0884 5.62394 63.0001C1.36579 55.6247 -0.401228 47.4947 0.0618223 39.5844C0.230679 46.3695 2.05031 53.2104 5.68279 59.502Z"
                  fill="#6F77FE"/>
              <path opacity="0.7"
                    d="M75.3113 64.6982C86.9093 44.6099 80.0265 18.9231 59.9382 7.32511C47.2253 -0.0147094 32.2701 0.0470637 20.0627 6.17339C32.763 -1.5867 49.2004 -2.33847 62.9971 5.62703C83.0853 17.225 89.9681 42.9118 78.3701 63.0001C74.112 70.3754 67.9547 75.9707 60.8726 79.5249C66.6643 75.9861 71.6788 70.9898 75.3113 64.6982Z"
                    fill="#6F77FE"/>
            </g>
          </g>
          <g>
            <circle cx="42" cy="42" r="40.8" stroke="#6F77FE" stroke-width="2.4"/>
          </g>
        </g>
        <defs>
          <filter id="filter0_f_1650_1093" x="-6.01007" y="-6.007" width="96.014" height="96.014"
                  filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_1650_1093"/>
          </filter>
          <clipPath id="clip0_1650_1093">
            <rect width="84" height="84" rx="42" fill="white"/>
          </clipPath>
          <clipPath id="clip1_1650_1093">
            <rect width="84" height="84" fill="white"/>
          </clipPath>
          <clipPath id="clip2_1650_1093">
            <rect width="84" height="84" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import {BrowserRecognizer} from "@/services/voiceAssistant/recognizers/browserRecognizer";
import {AssistantRecognizer} from "@/services/voiceAssistant/assistantRecognizer";
import {CommandDetector} from "@/services/voiceAssistant/commandDetector";
import {isEmpty} from "@/utils";
import {VoiceSynthesize} from "@/services/voiceAssistant/voiceSynthesize";
import {YandexRecognizer} from "@/services/voiceAssistant/recognizers/yandexRecognizer";

const STATUSES_CLASSES = {
  record: 'animationVoice',
  proceed: 'animationProceed',
  silent: ''
}
/*isBrowserSupport&&$root.site.domain === 'vip'*/

export default {
  name: "VoiceRecognitionView",
  data() {
    return {
      sr: null,
      transcript: '',
      rotateDeg: 0,
      answer: null,
      showFull: false,
      status: 'silent',
      isBrowserSupport: true,
      speaker: new Audio()
    }
  },
  methods: {
    handleVoiceClick() {
      if (!this.showFull) {
        this.showFull = true
      }

      this.ToggleMic()
    },
    hideBlock() {
      this.showFull = false
      this.sr.stop()
      this.status = 'silent'
    },
    ToggleMic() {
      if (this.status !== 'silent') {
        this.sr.stop()
        this.status = 'silent'

        if (this.transcript) {
          this.CheckForCommand(this.transcript)
        }

        return
      }

      if (this.speaker.src) {
        this.speaker.pause()
      }

      this.sr.start()
      this.status = 'record'
      this.transcript = null
      this.answer = null

    },
    async CheckForCommand(result) {
      this.sr.stop()
      this.status = 'proceed'
      const intent = await AssistantRecognizer.recognize(result)

      if (!intent) {
        this.status = 'silent'
        return;
      }

      this.answer = intent.answer

      try {
        //const audio = await VoiceSynthesize.synthesize(intent.answer)
        //const blob = new Blob([audio], { type: 'audio/mp3' });

        //this.speaker.src = URL.createObjectURL(blob)
        //await this.speaker.play();

        const command = CommandDetector.detect(intent)
        command.execute({
          object: this
        });

      } catch (err) {
        console.error(err)
      }

      this.status = 'silent'
    }
  },
  mounted() {
    try {
      this.sr = new BrowserRecognizer(this.recognizer)
    } catch (error) {
      this.sr = new YandexRecognizer(this.recognizer)
    }
  },
  computed: {
    STATUSES_CLASSES() {
      return STATUSES_CLASSES
    },
    recognizer() {
      return {
        onStart: () => {
          console.log('SR started')
        },
        onEnd: () => {
          console.log('SR Stopped')
        },
        onResult: (result) => {
          if (result.isFinal) {
            this.CheckForCommand(result.text)
          }

          this.transcript = result.text
        },
        alwaysListen: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.voice-assistant {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  background-color: white;
  color: white;
  padding: 20px;
  right: 0;
  bottom: 115px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  max-width: 350px;
  transition: 1000mss all;
  box-shadow: 0 0 20px 5px #0000001f;

  h4 {
    color: black;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    text-align: center;
    color: gray;
  }
}

.round-voice-indicator {
  cursor: pointer;
}

.animationVoice {
  transform-origin: 50% 50% 0;
  animation: 1s linear infinite rotate;

  path {
    fill: red;
  }
}

.animationProceed {
  transform-origin: 50% 50% 0;
  animation: 3s linear infinite rotate;

  path {
    fill: green;
  }
}

.close-assistant-button {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 10px;
  right: 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.5303 0.46967C13.8232 0.762563 13.8232 1.23744 13.5303 1.53033L1.53033 13.5303C1.23744 13.8232 0.762563 13.8232 0.46967 13.5303C0.176777 13.2374 0.176777 12.7626 0.46967 12.4697L12.4697 0.46967C12.7626 0.176777 13.2374 0.176777 13.5303 0.46967Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L13.5303 12.4697C13.8232 12.7626 13.8232 13.2374 13.5303 13.5303C13.2374 13.8232 12.7626 13.8232 12.4697 13.5303L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z' fill='black'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>