<template>
  <div class="agreement-wrapper">
    <div class="agreement-container">
      <div class="wrapper-privacy-header">
        <div class="privacy-header">
          {{$t('политикаКонфиденциальности')}}
        </div>
        <hr/>
      </div>
      <div class="privacy-description">
        <div>{{ $t('общиеПоложения') }}</div>
        <div>{{ $t('политикаВОтношенииОбработкиПерсональныхДанныхДалееПолитикаРазработанаВЦеляхРеализацииТребованийЗаконодательстваВОбластиОбработкиИЗащитыПерсональныхДанныхВСоответствииСПЧСтФедеральногоЗаконаОтГФзОПерсональныхДанных') }}</div>
        <div>{{ $t('настоящаяПолитикаОпределяетПорядокИУсловияОбработкиПерсональныхДанныхВОооАйтиРиэлтДалееОператорНаправленаНаЗащитуПравИСвободЧеловекаИГражданинаПриОбработкеЕгоПерсональныхДанныхИМерыПоОбеспечениюБезопасностиПерсональныхДанныхАИменноВсейИнформацииКоторуюОператорМожетПолучитьОПользователеВоВремяИспользованияПоследнимСайтаHttpsitrieltruАТакжеЕгоСубдоменов') }}</div>
        <div>{{ $t('политикаСодержитСведенияПодлежащиеРаскрытиюВСоответствииСЧСтФзОПерсональныхДанныхИЯвляетсяОбщедоступнымДокументом') }}</div>
        <div>{{ $t('сведенияОбОператоре') }}</div>
        <div>{{ $t('операторВедетСвоюДеятельностьПоАдресуКраснодарскийКрайГКраснодарУлСевернаяЭтажОф') }}</div>
        <div>{{ $t('директорПоПродуктуТелНазначенОтветственнымЗаОрганизациюОбработкиПерсональныхДанныхВСлучаеВозникновенияВопросовПоНастоящейПолитикеПользовательМожетСвязатьсяСОтветственнымЗаОрганизациюОбработкиПерсональныхДанныхНаправивПисьмоСПометкойЗапросОПерсональныхДанныхНаАдресЭлектроннойПочтыSupportitrieltruИлиПоАдресуУказанномуВПНастоящейПолитики') }}</div>
        <div>{{ $t('базаДанныхИнформацииСодержащейПерсональныеДанныеГражданРоссийскойФедерацииНаходитсяНаТерриторииРфПоАдресуКраснодарскийКрайГКраснодарУлСевернаяЭтажОф') }}</div>
        <div>{{ $t('сведенияОбОбработкеПерсональныхДанных') }}</div>
        <div>{{ $t('операторОбрабатываетПерсональныеДанныеНаЗаконнойИСправедливойОсновеДляВыполненияВозложенныхЗаконодательствомФункцийПолномочийИОбязанностейОсуществленияПравИЗаконныхИнтересовОператораРаботниковОператораИТретьихЛиц') }}</div>
        <div>{{ $t('операторПолучаетПерсональныеДанныеНепосредственноПредоставленныеПользователем') }}</div>
        <div>{{ $t('терминыИОпределения') }}</div>
        <div>{{ $t('вНастоящейПолитикеИспользуютсяСледующиеТерминыИОпределения') }}</div>
        <div>{{ $t('администрацияСайтаHttpsitrieltruДалееАдминистрацияСайтаОператорСотрудникиОператораУполномоченныеНаУправлениеСайтомДействующиеОтИмениОператораКоторыеОрганизуютИИлиОсуществляетОбработкуПерсональныхДанныхАТакжеОпределяетЦелиОбработкиПерсональныхДанныхСоставПерсональныхДанныхПодлежащихОбработкеДействияОперацииСовершаемыеСПерсональнымиДанными') }}</div>
        <div>{{ $t('персональныеДанныеЛюбаяИнформацияОтносящаясяКПрямоИлиКосвенноОпределенномуФизическомуЛицуПользователю') }}</div>
        <div>{{ $t('обработкаПерсональныхДанныхЛюбоеДействиеОперацияИлиСовокупностьДействийОперацийСовершаемыхСИспользованиемСредствАвтоматизацииИлиБезИспользованияТакихСредствСПерсональнымиДаннымиВключаяСборЗаписьСистематизациюНакоплениеХранениеУточнениеОбновлениеИзменениеИзвлечениеИспользованиеПередачуРаспространениеПредоставлениеДоступОбезличиваниеБлокированиеУдалениеУничтожениеПерсональныхДанных') }}</div>
        <div>{{ $t('конфиденциальностьПерсональныхДанныхОбязательноеДляСоблюденияОператоромИлиИнымПолучившимДоступКПерсональнымДаннымЛицомТребованиеНеДопускатьИхРаспространенияБезСогласияПользователяИлиНаличияИногоЗаконногоОснования') }}</div>
        <div>{{ $t('пользовательСайтаHttpsitrieltruДалееПользовательЛицоИмеющееДоступИИспользующееСайтПоСредствамСетиИнтернет') }}</div>
        <div>{{ $t('cookiesНебольшойФрагментДанныхТекстовыеФайлыРазмещаемыйНаЖесткихДискахУстройствПользователяВоВремяИспользованияРазличныхСайтовПредназначенныхДляСодействияВНастройкеПользовательскогоИнтерфейсаВСоответствииСПредпочтениямиПользователяОтправленныйВебсерверомИХранимыйНаУстройствахПользователяКоторыйВебклиентИлиВеббраузерКаждыйРазПересылаетВебсерверуВHttpзапросеПриПопыткеОткрытьСтраницуСоответствующегоСайта') }}</div>
        <div>{{ $t('ipадресУникальныйСетевойАдресУзлаВКомпьютернойСетиПостроеннойПоПротоколуIp') }}</div>
        <div>{{ $t('сведенияОПравилахПользованияСайтом') }}</div>
        <div>{{ $t('использованиеПользователемСайтаHttpsitrieltruИПредоставлениеОператоруСвоихПерсональныхДанныхОзначаетБезусловноеСогласиеСНастоящейПолитикойИУсловиямиОбработкиПерсональныхДанныхПользователя') }}</div>
        <div>{{ $t('вСлучаеНесогласияСУсловиямиПолитикиПользовательДолженПрекратитьИспользованиеСайтаHttpsitrieltru') }}</div>
        <div>{{ $t('операторСобираетИспользуетИОхраняетПерсональныеДанныеКоторыеПользовательПредоставляетОператоруПриИспользованииСайтаHttpsitrieltruДалееСайтСЛюбогоУстройстваИПриКоммуникацииСОператоромВЛюбойФормеВСоответствииСДаннойПолитикой') }}</div>
        <div>{{ $t('настоящаяПолитикаРаспространяетсяНаСайтHttpsitrieltruАТакжеЕгоСубдоменыИНаИнформациюСобираемуюУказаннымСайтомИЕгоСубдоменамиЧерезИхПосредствоОператорНеКонтролируетИНеНесетОтветственностьЗаСайтыТретьихЛицНаКоторыеПользовательМожетПерейтиПоСсылкамДоступнымНаСайтеHttpsitrieltru') }}</div>
        <div>{{ $t('операторНеПроверяетДостоверностьПерсональныхДанныхПредоставляемыхПользователемСайтаHttpsitrieltru') }}</div>
        <div>{{ $t('пользователиНеИмеютПраваИспользоватьРазмещенныеНаСайтеВОткрытомДоступеПерсональныеДанныеКромеКакВЦеляхДляКоторыхСоответствующиеДанныеБылиРазмещеныНаСайтеНапримерКонсультацияОператораПоПоводуТоваровИУслугРеализуемыхОператоромЗаписьНаМероприятиеОператораИПрПользователиПредупрежденыЧтоИспользованиеПерсональныхДанныхРазмещенныхНаСайтеВИныхЦеляхМожетПовлечьЗаСобойПредусмотреннуюЗаконодательствомРоссийскойФедерацииОтветственностьВСлучаеЕслиПоВинеПользователяАдминистрацииБудутПричиненыКакиелибоУбыткиПользовательОбязуетсяВозместитьИхАдминистрацииВПолномОбъме') }}</div>
        <div>{{ $t('основныеПоложения') }}</div>
        <div>{{ $t('предметПолитики') }}</div>
        <div>{{ $t('настоящаяПолитикаУстанавливаетОбязательстваОператораПоНеразглашениюИОбеспечениюРежимаЗащитыКонфиденциальностиПерсональныхДанныхКоторыеПользовательПредоставляетПоЗапросуОператораПриРегистрацииНаСайтеHttpsitrieltruИлиПриОформленииЗаказаНаПолучениеЛицензийУслугИлиСправочнойИнформации') }}</div>
        <div>{{ $t('персональныеДанныеРазрешнныеКОбработкеВРамкахНастоящейПолитикиПредоставляютсяПользователемПутмЗаполненияФормНаСайтеHttpsitrieltruИВключаютВСебяСледующуюИнформацию') }}</div>
        <div>{{ $t('фамилиюИмяОтчествоПользователяВТчПоОтдельностиИВСлучаеИзменения') }}</div>
        <div>{{ $t('контактныйТелефонПользователя') }}</div>
        <div>{{ $t('адресЭлектроннойПочтыEmailПользователя') }}</div>
        <div>{{ $t('аТакжеЛюбуюИнуюИнформациюОтносящуюсяКЛичностиПользователяКоторуюПользовательПожелаетОставитьНаСайтеHttpsitrieltruВТчНоНеОграничиваясьРеквизитыДокументаУдостоверяющегоЛичностьИИныхПредставленныхПользователемДокументовГражданствоДатаРожденияМестоРожденияАдресРегистрацииАдресФактическогоМестаЖительстваСсылкаНаПерсональныйСайтИПрофильВСоцсетяхДанныеОПрофессииМестеРаботыДолжностиОбразованииИДоходахИДругуюПерсональнуюИнформацию') }}</div>
        <div>{{ $t('операторИспользуетТехнологиюCookiesДляСозданияСтатистическойОтчетностиПриПосещенииПользователемСайтаHttpsitrieltruОператорОпределяетИмяДоменаПровайдераПользователяИСтрануНапримерAolcomИВыбранныеПереходыСОднойСтраницыНаДругуюТакНазываемуюАктивностьПотокаПереходов') }}</div>
        <div>{{ $t('сведенияКоторыеОператорПолучаетНаСайтеHttpsitrieltruМогутБытьИспользованыДляТогоЧтобыОблегчитьПользователюПользованиеСайтомВключаяНоНеОграничиваясь') }}</div>
        <div>{{ $t('организацияСайтаНаиболееУдобнымДляПользователейСпособом') }}</div>
        <div>{{ $t('предоставлениеВозможностиПодписатьсяНаПочтовуюРассылкуПоСпециальнымПредложениямИТемамЕслиВыХотитеПолучатьТакиеУведомления') }}</div>
        <div>{{ $t('вCookiesСодержитсяИнформацияКотораяМожетБытьНеобходимойДляСайтаДляСохраненияУстановокВариантовПросмотраИСбораСтатистическойИнформацииПоСайтуТеКакиеСтраницыПользовательПосетилЧтоБылоЗагруженоИмяДоменаИнтернетпровайдераИСтранаПосетителяАТакжеАдресаСтороннихВебсайтовСКоторыхСовершенПереходНаСайтИДалееОднакоВсяЭтаИнформацияНикакНеСвязанаСПользователемКакСЛичностьюCookiesНеЗаписываютАдресЭлектроннойПочтыПользователяИКакиелибоЛичныеСведенияОтносительноПользователяТакжеДаннуюТехнологиюНаСайтеИспользуетУстановленныеСчетчикиЯндексМетрикаИGoogleAnalytics') }}</div>
        <div>{{ $t('кромеТогоОператорИспользуетСтандартныеЖурналыУчетаВебсервераДляПодсчетаКоличестваПосетителейИОценкиТехническихВозможностейСайтаHttpsitrieltruОператорИспользуетЭтуИнформациюДляТогоЧтобыОпределитьСколькоЧеловекПосещаетСайтИОрганизоватьСтраницыНаиболееУдобнымДляПользователейСпособомОбеспечитьСоответствиеСайтаИспользуемымБраузерамИСделатьСодержаниеСтраницСайтаHttpsitrieltruМаксимальноПолезнымДляПользователейОператорЗаписываетСведенияПоПеремещениямНаСайтеНоНеОбОтдельныхПосетителяхСайтаВСвязиСЧемНикакаяКонкретнаяИнформацияОтносительноПользователяЛичноНеБудетСохранятьсяИлиИспользоватьсяОператоромБезСогласияПользователя') }}</div>
        <div>{{ $t('чтобыПросматриватьМатериалБезCookiesПользовательМожетеНастроитьСвойБраузерТакимОбразомЧтобыОнНеПринималCookiesЛибоУведомлялПользователяОбИхПосылкеСпособыРешенияРазличныПоэтомуСоветуемПользователюУточнитьНеобходимуюИнформациюВРазделеПомощьИВыяснитьКакИзменитьУстановкиУстройстваПользователяПоCookies') }}</div>
        <div>{{ $t('вниманиеОтключениеТехнологииCookiesМожетПовлечьНевозможностьДоступаКЧастямСайтаHttpsitrieltru') }}</div>
        <div>{{ $t('операторОсуществляетСборСтатистикиОбIpадресахПосетителейСайтаHttpsitrieltruДаннаяИнформацияИспользуетсяСЦельюВыявленияИРешенияТехническихПроблем') }}</div>
        <div>{{ $t('любаяИнаяПерсональнаяИнформацияНеоговореннаяВышеПодлежитНадежномуХранениюИНераспространениюЗаИсключениемСлучаевПредусмотренныхДействующимЗаконодательствомРф') }}</div>
        <div>{{ $t('принципыОбработкиПерсональныхДанных') }}</div>
        <div>{{ $t('обработкаПерсональныхДанныхУОператораОсуществляетсяНаОсновеСледующихПринципов') }}</div>
        <div>{{ $t('законностиИСправедливойОсновы') }}</div>
        <div>{{ $t('ограниченияОбработкиПерсональныхДанныхДостижениемКонкретныхЗаранееОпределенныхИЗаконныхЦелей') }}</div>
        <div>{{ $t('недопущенияОбработкиПерсональныхДанныхНесовместимойСЦелямиСбораПерсональныхДанных') }}</div>
        <div>{{ $t('недопущенияОбъединенияБазДанныхСодержащихПерсональныеДанныеОбработкаКоторыхОсуществляетсяВЦеляхНесовместимыхМеждуСобой') }}</div>
        <div>{{ $t('обработкиТолькоТехПерсональныхДанныхКоторыеОтвечаютЦелямИхОбработки') }}</div>
        <div>{{ $t('соответствияСодержанияИОбъемаОбрабатываемыхПерсональныхДанныхЗаявленнымЦелямОбработки') }}</div>
        <div>{{ $t('недопущенияОбработкиПерсональныхДанныхИзбыточныхПоОтношениюКЗаявленнымЦелямИхОбработки') }}</div>
        <div>{{ $t('обеспеченияТочностиДостаточностиИАктуальностиПерсональныхДанныхПоОтношениюКЦелямОбработкиПерсональныхДанных') }}</div>
        <div>{{ $t('уничтоженияЛибоОбезличиванияПерсональныхДанныхПоДостиженииЦелейИхОбработкиИлиВСлучаеУтратыНеобходимостиВДостиженииЭтихЦелейПриНевозможностиУстраненияОператоромДопущенныхНарушенийПерсональныхДанныхЕслиИноеНеПредусмотреноФедеральнымЗаконом') }}</div>
        <div>{{ $t('условияОбработкиПерсональныхДанных') }}</div>
        <div>{{ $t('операторПроизводитОбработкуПерсональныхДанныхПриНаличииХотяБыОдногоИзСледующихУсловий') }}</div>
        <div>{{ $t('обработкаПерсональныхДанныхОсуществляетсяССогласияПользователяНаОбработкуЕгоПерсональныхДанных') }}</div>
        <div>{{ $t('обработкаПерсональныхДанныхНеобходимаДляДостиженияЦелейОсуществленияИВыполненияВозложенныхЗаконодательствомРоссийскойФедерацииНаОператораФункцийПолномочийИОбязанностей') }}</div>
        <div>{{ $t('обработкаПерсональныхДанныхНеобходимаДляИсполненияДоговораСторонойКоторогоЯвляетсяПользовательЗаключенныйПоИнициативеПользователя') }}</div>
        <div>{{ $t('обработкаПерсональныхДанныхНеобходимаДляОсуществленияПравИЗаконныхИнтересовОператораИлиТретьихЛицЛибоДляДостиженияОбщественноЗначимыхЦелейПриУсловииЧтоПриЭтомНеНарушаютсяПраваИСвободыПользователя') }}</div>
        <div>{{ $t('осуществляетсяОбработкаПерсональныхДанныхДоступНеограниченногоКругаЛицККоторымПредоставленПользователемЛибоПоЕгоПросьбеДалееОбщедоступныеПерсональныеДанные') }}</div>
        <div>{{ $t('осуществляетсяОбработкаПерсональныхДанныхПодлежащихОпубликованиюИлиОбязательномуРаскрытиюВСоответствииСФедеральнымЗаконом') }}</div>
        <div>{{ $t('целиСбораПерсональныхДанных') }}</div>
        <div>{{ $t('персональныеДанныеПользователяОператорМожетИспользоватьВЦелях') }}</div>
        <div>{{ $t('идентификацииПользователяДляОформленияЗаказаИИлиЗаключенияДоговора') }}</div>
        <div>{{ $t('предоставленияПользователюДоступаКПерсонализированнымРесурсамСайтаHttpsitrieltru') }}</div>
        <div>{{ $t('установленияСПользователемОбратнойСвязиВключаяНаправлениеУведомленийЗапросовКасающихсяИспользованияСайтаHttpsitrieltruПредоставленияЛицензийИОказанияУслугОбработкиЗапросовИЗаявокОтПользователя') }}</div>
        <div>{{ $t('уведомленияПользователяСайтаHttpsitrieltruОСостоянииЗаказапредоставленияПользователюЭффективнойКлиентскойИТехническойПоддержкиПриВозникновенииПроблемСвязанныхСИспользованиемСайтаHttpsitrieltru') }}</div>
        <div>{{ $t('предоставленияПользователюИнформацииОбОбновленииСпискаПриложенийСпециальныхПредложенийИнформацииОЦенахНовостнойРассылкиИИныхСведенийОтИмениОператораИлиОтИмениПартнеровОператора') }}</div>
        <div>{{ $t('осуществленияРекламнойРассылки') }}</div>
        <div>{{ $t('использованиеТехнологииCookiesДляСозданияСтатистическойОтчетностиСтандартныхЖурналовУчетаВебсервераДляПодсчетаКоличестваПосетителейИОценкиТехническихВозможностейСайта') }}</div>
        <div>{{ $t('предоставленияДоступаПользователюНаСайтыИлиСервисыПартнеровОператораСЦельюПолученияПродуктовОбновленийИУслуг') }}</div>
        <div>{{ $t('способыИСрокиОбработкиПерсональныхДанных') }}</div>
        <div>{{ $t('обработкаПерсональныхДанныхПользователяОсуществляетсяБезОграниченияСрокаЛюбымЗаконнымСпособомВТомЧислеВИнформационныхСистемахПерсональныхДанныхСИспользованиемСредствАвтоматизацииИлиБезИспользованияТакихСредств') }}</div>
        <div>{{ $t('пользовательСоглашаетсяСТемЧтоОператорВправеПередаватьПерсональныеДанныеТретьимЛицам') }}</div>
        <div>{{ $t('персональныеДанныеПользователяМогутБытьПереданыУполномоченнымОрганамГосударственнойВластиРоссийскойФедерацииТолькоПоОснованиямИВПорядкеУстановленнымЗаконодательствомРоссийскойФедерации') }}</div>
        <div>{{ $t('операторПринимаетНеобходимыеОрганизационныеИТехническиеМерыДляЗащитыПерсональнойИнформацииПользователяОтНеправомерногоИлиСлучайногоДоступаУничтоженияИзмененияБлокированияКопированияРаспространенияАТакжеОтИныхНеправомерныхДействийТретьихЛиц') }}</div>
        <div>{{ $t('конфиденциальностьПерсональныхДанных') }}</div>
        <div>{{ $t('операторИспользуетПолученнуюОтПользователяИнформациюИсключительноДляЦелейУказанныхВПунктеНастоящейПолитики') }}</div>
        <div>{{ $t('обеспечитьХранениеКонфиденциальнойИнформацииВТайнеНеРазглашатьБезПредварительногоПисьменногоРазрешенияПользователяАТакжеНеОсуществлятьПродажуОбменОпубликованиеЛибоРазглашениеИнымиВозможнымиСпособамиПереданныхПерсональныхДанныхПользователяЗаИсключениемСлучаевПредусмотренныхЗаконодательствомРф') }}</div>
        <div>{{ $t('приниматьМерыПредосторожностиДляЗащитыКонфиденциальностиПерсональныхДанныхПользователяСогласноПорядкуОбычноИспользуемогоДляЗащитыТакогоРодаИнформацииВСуществующемДеловомОбороте') }}</div>
        <div>{{ $t('осуществитьБлокированиеПерсональныхДанныхОтносящихсяКСоответствующемуПользователюСМоментаОбращенияИлиЗапросаПользователяИлиЕгоЗаконногоПредставителяЛибоУполномоченногоОрганаПоЗащитеПравПользователейНаПериодПроверкиВСлучаеВыявленияНедостоверныхПерсональныхДанныхИлиНеправомерныхДействий') }}</div>
        <div>{{ $t('биометрическиеПерсональныеДанные') }}</div>
        <div>{{ $t('сведенияКоторыеХарактеризуютФизиологическиеИБиологическиеОсобенностиЧеловекаНаОснованииКоторыхМожноУстановитьЕгоЛичностьБиометрическиеПерсональныеДанныеМогутОбрабатыватьсяОператоромТолькоПриНаличииСогласияПользователяВПисьменнойФорме') }}</div>
        <div>{{ $t('праваПользователя') }}</div>
        <div>{{ $t('пользовательПринимаяРешениеОПредоставленииСвоихПерсональныхДанныхДействуетСвободноСвоейВолейИВСвоемИнтересеБезусловноВыражаяСвоеСогласиеСогласиеНаОбработкуПерсональныхДанныхМожетБытьДаноПользователемИлиЕгоПредставителемКакВПисьменнойФормеТакИПутемВнесенияПерсональныхДанныхВПоляхФормыРегистрацииНаСайтеHttpsitrieltruИилиПутемОтправкиПерсональныхДанныхНаКорпоративнуюЭлектроннуюПочтуSupportitrieltruТемСамымПрисоединяясьКСогласиюОбОбработкеПерсональныхДанныхРазмещенномВСетиИнтернетПризнаетсяИсполненнымВПростойПисьменнойФорме') }}</div>
        <div>{{ $t('пользовательИмеетПравоНаПолучениеУОператораИнформацииКасающейсяОбработкиПерсональныхДанныхЕслиТакоеПравоНеОграниченоВСоответствииСЗаконодательствомРфПользовательВправеТребоватьОтОператораУточненияЕгоПерсональныхДанныхИхБлокированияИлиУничтоженияВСлучаеЕслиПерсональныеДанныеЯвляютсяНеполнымиУстаревшимиНеточнымиНезаконноПолученнымиИлиНеЯвляютсяНеобходимымиДляЗаявленнойЦелиОбработкиАТакжеПриниматьПредусмотренныеЗакономМерыПоЗащитеСвоихПрав') }}</div>
        <div>{{ $t('обеспечениеБезопасностиПерсональныхДанных') }}</div>
        <div>{{ $t('безопасностьПерсональныхДанныхОбрабатываемыхОператоромОбеспечиваетсяРеализациейПравовыхОрганизационныхИТехническихМерНеобходимыхДляОбеспеченияТребованийФедеральногоЗаконодательстваВОбластиЗащитыПерсональныхДанных') }}</div>
        <div>{{ $t('дляПредотвращенияНесанкционированногоДоступаКПерсональнымДаннымОператоромПрименяютсяСледующиеОрганизационнотехническиеМеры') }}</div>
        <div>{{ $t('назначениеДолжностныхЛицОтветственныхЗаОрганизациюОбработкиПерсональныхДанных') }}</div>
        <div>{{ $t('ограничениеСоставаЛицИмеющихДоступКПерсональнымДанным') }}</div>
        <div>{{ $t('изданиеВнутреннихНормативныхДокументовОператораПоВопросамОбеспеченияЗащитыПерсональныхДанныхИИхОбработкиАТакжеУстанавливающиеПроцедурыНаправленныеНаПредотвращениеИВыявлениеНарушенийЗаконодательстваРфИУстранениеИхПоследствий') }}</div>
        <div>{{ $t('ознакомлениеОтветственныхЗаОрганизациюОбработкиПерсональныхДанныхСТребованиямиФедеральногоЗаконодательстваИВнутреннихНормативныхДокументовОператораПоОбработкеПерсональныхДанных') }}</div>
        <div>{{ $t('организацияУчетаХраненияИОбращенияНосителейИнформации') }}</div>
        <div>{{ $t('разграничениеДоступаПользователейКИнформационнымРесурсамИПрограммноаппаратнымСредствамОбработкиИнформации') }}</div>
        <div>{{ $t('регистрацияИУчетДействийПользователейИнформационныхСистемПерсональныхДанных') }}</div>
        <div>{{ $t('использованиеАнтивирусныхСредствИСредствВосстановленияСистемыЗащитыПерсональныхДанных') }}</div>
        <div>{{ $t('организацияРежимаОграниченногоДоступаВПомещенияОбработкиПерсональныхДанныхНаТерриторииОператора') }}</div>
        <div>{{ $t('операторНеНестОтветственностьВСлучаеУтратыИлиРазглашенияПерсональныхДанныхПользователяЕслиОни') }}</div>
        <div>{{ $t('сталиПубличнымДостояниемДоИхУтратыИлиРазглашенияОператором') }}</div>
        <div>{{ $t('былиПолученыОператоромОтТретьейСтороныДоМоментаИхПередачиОператоруПользователем') }}</div>
        <div>{{ $t('былиРазглашеныССогласияПользователя') }}</div>
        <div>{{ $t('разрешениеСпоров') }}</div>
        <div>{{ $t('доОбращенияВСудСИскомПоСпорамВозникающимИзОтношенийМеждуПользователемСайтаHttpsitrieltruИОператоромОбязательнымЯвляетсяПредъявлениеПретензииПисьменногоПредложенияОДобровольномУрегулированииСпора') }}</div>
        <div>{{ $t('получательПретензииВТечениеТридцатиКалендарныхДнейСоДняПолученияПретензииПисьменноУведомляетЗаявителяПретензииОРезультатахРассмотренияПретензии') }}</div>
        <div>{{ $t('приНеДостиженииСоглашенияСпорБудетПереданНаРассмотрениеВСудебныйОрганВСоответствииСДействующимЗаконодательствомРоссийскойФедерации') }}</div>
        <div>{{ $t('кНастоящейПолитикеИОтношениямМеждуПользователемИОператоромПрименяетсяДействующееЗаконодательствоРоссийскойФедерации') }}</div>
        <div>{{ $t('дополнительныеУсловия') }}</div>
        <div>{{ $t('всеВопросыСвязанныеСОбработкойПерсональныхДанныхНеУрегулированныеНастоящейПолитикойРазрешаютсяВСоответствииСДействующимЗаконодательствомРоссийскойФедерацииВОбластиПерсональныхДанных') }}</div>
        <div>{{ $t('операторВправеВноситьИзмененияВНастоящуюПолитикуБезСогласияПользователя') }}</div>
        <div>{{ $t('новаяРедакцияПолитикиВступаетВСилуСМоментаЕеРазмещенияНаСайтеHttpsitrieltruЕслиИноеНеПредусмотреноНовойРедакциейПолитики') }}</div>
        <div>{{ $t('всеПредложенияИлиВопросыПоНастоящейПолитикеСледуетНаправлятьПоАдресуЭлектроннойПочтыSupportitrieltru') }}</div>
        <div>{{ $t('действующаяПолитикаРазмещенаВСетиИнтернетПоАдресуHttpsitrieltrulegacy') }}</div>
        <div>{{ $t('российскаяФедерацияГородКраснодар') }}</div>
        <div>{{ $t('датаРазмещенияВСетиИнтернетИВступленияВСилуНастоящейРедакцииОктябряГ') }}</div>
      </div>
      <div class="agreement-footer" v-if="!profile.agreement">
        <div  class="agreement-footer__checkbox">
          <input class="form-check-input" type="checkbox" v-model="agreementValue" id="regulation"> <label for="regulation">{{ $t('яСоглашаюсьНаОбработкуПерсональныхДанных') }}</label>
        </div>
        <div class="agreement-footer__btn" :style="agreementValue?{}:{opacity:'0.3'}" @click="submitAgreement">{{ $t('продолжить') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserAgreement",
  data(){
    return{
      agreementValue:false,
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
      }
    },
  computed:{
    profile () {
      return this.$store.getters.getProfile
    },
  },
  methods:{
    closeAgreement(option){
        this.$emit('change-agreement',option)
    },
    async submitAgreement(){
      if(this.agreementValue){
        // console.log('Есть')
        const obj={}
        let user = this.$store.getters.getProfile
        obj.id= user.id
        obj.agreement= 1
        await axios.post('/api/save-profile', obj)
        this.closeAgreement()
      }
    }
},
  mounted() {
    $('body').css('overflow', 'hidden');
  },
  beforeDestroy() {
    $('body').css('overflow', '');
  }
}
</script>

<style lang="scss">
.agreement-wrapper{
  position: fixed;
  z-index: 3500;
  top:0;
  left: 80px;
  right: 0;
  bottom: 0;
  background-color: var(--agreement-back);
  font-style: normal;
  overflow: auto;
  .agreement-container{
    width: 90%;
    margin: 0 auto;
    hr{
      background-color: #ccc;
      opacity: 1;
    }
    .wrapper-privacy-header{
      position: sticky;
      top: 0;
      background-color: var(--agreement-back);
      width: 100%;
      .privacy-header-close{
        position: absolute;
        right: 0;
        top: 5px;
        font-size: 30px;
        font-weight: 500;
        cursor: pointer;
      }
      .privacy-header{
        font-size: 21px;
        font-weight: 500;
        padding: 10px 0 0;
        color: var(--agreement-title);
        word-wrap: break-word;
      }
    }
    .privacy-description{
      div{

        margin: 0 0 1em;
        color: var(--black-basic);
      }
    }
    .agreement-title{
      text-align: center;
      font-size: 21px;
      font-weight: 500;
      padding: 10px 0 0;
      color:var(--agreement-title);
      word-wrap: break-word;
    }
    .agreement-footer{
      color: var(--agreement-title);
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 40px;
      .agreement-footer__checkbox{
        cursor: pointer;
        label{
          cursor: pointer;
        }
      }
      .agreement-footer__btn{
        cursor: pointer;
        background: var(--blue-900-basic);
        border-radius: 30px;
        border: 0;
        padding: 10px 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        color: var(--white-basic);
      }
    }
  }
}
@media screen and (max-width: 1090px){
  .agreement-wrapper{
    left: 0;
  }
}
@media screen and (max-width: 650px){
  .agreement-wrapper .agreement-container .agreement-footer {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px){
  .agreement-wrapper .agreement-container .agreement-footer .agreement-footer__checkbox label  {
    font-size: 11px;
  }
}
@media screen and (max-width: 360px){
  .agreement-wrapper .agreement-container .agreement-footer .agreement-footer__checkbox label  {
    font-size: 10px;
  }
}
</style>