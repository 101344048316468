import Vue from "vue";

export const profileModule ={
    state: ()=>({
        user: {},
        baseUrl:'/profile',
        responsibleList: {},
        optionsPerson: [],
        selectedPerson:{
            img:null,
            name:'',
            description:'',
            value:null,
            groups:null
        },
        optionsEntity:[],
    }),
    getters:{
        getUser: state => state.user,
        getBaseUrl: state => state.baseUrl,
        getResponsibleList: state => state.responsibleList,
        getOptionsPerson: state => state.optionsPerson,
        getSelectedPerson: state => state.selectedPerson,
        getOptionsEntity: state => state.optionsEntity,
    },
    mutations:{
        setUserAll: (state, resp) => {
            Vue.set(state, 'user', resp)
        },
        setUserObj: (state, obj) => {
            Vue.set(state, 'user', {...state.user, ...obj })
        },

        setBaseUrl: (state, resp) => {
            Vue.set(state, 'baseUrl', resp)
        },

        setResponsibleListAll: (state, resp) => {
            Vue.set(state, 'responsibleList', resp)
        },
        setResponsibleListObj: (state, obj) => {
            Vue.set(state, 'responsibleList', {...state.responsibleList, ...obj })
        },

        setOptionsPersonAll: (state, resp) => {
            Vue.set(state, 'optionsPerson', resp)
        },

        setSelectedPersonAll: (state, resp) => {
            Vue.set(state, 'selectedPerson', resp)
        },
        setSelectedPersonObj: (state, obj) => {
            Vue.set(state, 'selectedPerson', {...state.selectedPerson, ...obj })
        },

        setOptionsEntityAll: (state, resp) => {
            Vue.set(state, 'optionsEntity', resp)
        },
    },
    actions:{
        SET_USER: ({ commit, dispatch, getters }, string) => {
            commit('SET_USER', string)
        },
    },
    namespaced:true
}