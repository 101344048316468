import {OpenPageCommand} from "@/services/voiceAssistant/commands/openPageCommand";
import {AppealCommand} from "@/services/voiceAssistant/commands/appealCommand";
import {DefineFilterCommand} from "@/services/voiceAssistant/commands/defineFilterCommand";
import {EmptyCommand} from "@/services/voiceAssistant/commands/emptyCommand";

export class CommandDetector {
    static get registeredCommands() {
        return {
            'open_page': OpenPageCommand,
            'appeal': AppealCommand,
            'define_filter': DefineFilterCommand,
            'dialog': EmptyCommand
        }
    }

    static detect(intent) {
        if (this.registeredCommands.hasOwnProperty(intent.intention)) {
            const context = {
                subject: intent.subject,
                intent: intent
            }

            return new this.registeredCommands[intent.intention](context)
        }
    }
}