<template>
  <div class="tabs-container" :class="[{'vertical': setVertical},{'stretched':stretched}, {'block-scroll-tabs':disabledScroll}, mode, size]" v-if="tabs.length" v-dragScrollMouse>
    <template v-if="tabs[0] !== null && typeof tabs[0] === 'object'">
      <div class="tab" :class="{'active': (tab.value === value.value && activeVisible), 'stretched':stretched}" v-for="(tab,index) in tabs" :key="index+tab.value" @click="onTabSelected(tab)">
        <div class="tab-content-wrapper">
          <div class="tab-content">
            <div class="tab-icon" v-if="tab.icon&&iconVisible" :style="`mask: url(${tab.icon}) no-repeat center / contain;`"></div>
            <div class="tab-value-wrapper" v-if="tab.value&&labelVisible">
              <span class="tab-value">{{ tab.value }}</span>
            </div>
          </div>
        </div>
        <div v-if="mode === 'primary'" class="line"></div>
      </div>
    </template>
    <template v-else>
      <div class="tab" :class="{'active': (tab === value && activeVisible)}" v-for="(tab,index) in tabs" :key="index+tab" @click="onTabSelected(tab)">
        <div class="tab-content-wrapper" v-if="tab&&labelVisible">
          <div class="tab-content">
            <div class="tab-value-wrapper">
              <span class="tab-value">{{ tab }}</span>
            </div>
          </div>
        </div>
        <div v-if="mode === 'primary'" class="line"></div>
      </div>
    </template>
  </div>
</template>

<script>
/**
 * @prop {Array} tabs - Массив двух видов: 1. Массив строк с именами табов 2. Массив объектов, где каждый объект содержит поле icon - ссылка на иконку, поле value - значение таба {icon:'Ссылка',value:'Значение'}
 * @prop {String} value - Текущий таб
 * @prop {String} mode - Тема табов. Доступны три варианта: accent, primary, secondary
 * @prop {String} size - Размер табов. Доступны варианты: small, medium, large. Если проп отсутствует, то размер от 744 и выше будет large, а 0-743px будет medium
 * @prop {Boolean} iconVisible - Отвечает за отображение иконок
 * @prop {Boolean} labelVisible - Отвечает за отображение надписей
 * @prop {Boolean} stretched - Включает/выключает растягивание табов на всю ширину
 * @prop {Boolean} activeVisible - Включает/выключает отображение активного таба
 * @prop {Boolean} disabledScroll - Включает/выключает скролл у табов. По дефолту скролл включён
 */
export default {
  name: "defaultTabs",
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      }
    },
    value: {},
    mode: {
      type: String,
      default: 'accent'
    },
    size: {
      type: String,
      default: ''
    },
    setVertical: {
      type: Boolean,
      default: false
    },
    iconVisible: {
      type: Boolean,
      default: true
    },
    labelVisible: {
      type: Boolean,
      default: true
    },
    stretched: {
      type: Boolean,
      default: false
    },
    activeVisible: {
      type: Boolean,
      default: true
    },
    disabledScroll: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onTabSelected(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped lang="scss">
.tabs-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
  flex-direction: row;
  white-space: nowrap;
  overflow: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display: none;
  }
  &.vertical {
    flex-direction: column;
    width: 100%;
  }

  &.accent {
    .tab {
      background: var(--colorBackgroundSecondary);
      color: var(--colorTextSecondary);
      &.active{
        background: var(--colorBackgroundAccent);
        color: var(--colorTextContrast);
        &:hover{
          background: var(--colorBackgroundAccentHover);
        }
        .tab-content-wrapper{
          .tab-content{
            .tab-icon{
              background-color: var(--colorIconContrast);
            }
          }
        }
      }
      &:hover{
        background: var(--colorBackgroundSecondaryHover);
      }
      .tab-content-wrapper{
        padding: 6px 0;
        .tab-content{
          .tab-value{
            padding: 1px 4px;
          }
        }
      }
    }
    &.small{
      .tab {
        .tab-content-wrapper{
          padding: 4px 0;
          .tab-content{

          }
        }
      }
    }
  }

  &.secondary {
    .tab {
      background: transparent;
      color: var(--colorTextSecondary);
      &.active{
        background: var(--colorBackgroundTertiaryActive);
        color: var(--colorTextAccent);
        .tab-content-wrapper{
          .tab-content{
            .tab-icon{
              background-color: var(--colorIconAccent);
            }
          }
        }
      }
      &:hover{
        background: var(--colorBackgroundTertiaryHover);
      }
      .tab-content-wrapper{
        padding: 6px 0;
        .tab-content{
          .tab-value{
            padding: 1px 4px;
          }
        }
      }
    }
    &.small{
      .tab {
        .tab-content-wrapper{
          padding: 4px 0;
          .tab-content{

          }
        }
      }
    }
  }

  &.primary {
    .tab {
      background: transparent;
      color: var(--colorTextSecondary);
      &.active{
        background: transparent;
        color: var(--colorTextPrimary);

        .line {
          background-color: var(--colorIconAccent);
        }
        .tab-content-wrapper{
          .tab-content{
            .tab-icon{
              background-color: var(--colorIconPrimary);
            }
          }
        }
      }
      &:hover{
        background: var(--colorBackgroundSecondaryHover);
      }
      .tab-content-wrapper{
        padding-top: 6px;
        .tab-content{
          .tab-value{
            padding: 0 4px 2px;
          }
        }
      }
    }
  }

  &.large {
    .tab {
      padding: 4px 12px;
      .tab-content-wrapper{
        .tab-content{
          gap: 4px;
          height: 24px;
          .tab-icon{
            width: 24px;
            height: 24px;
          }
        }
      }
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.024px;
      }
    }
  }

  &.medium {
    .tab {
      padding: 2px 8px;
      .tab-content-wrapper{
        .tab-content{
          gap: 2px;
          height: 20px;
          .tab-icon{
            width: 20px;
            height: 20px;
          }
        }
      }
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.07px;
      }
    }
  }

  &.small {
    .tab {
      padding: 0 8px;
      .tab-content-wrapper{
        .tab-content{
          height: 20px;
          .tab-icon{
            width: 16px;
            height: 16px;
          }
        }
      }
      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.03px;
      }
    }
  }

  .tab {
    padding: 4px 12px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.1s ease;
    width: max-content;
    display: flex;
    flex-direction: column;
    .tab-content-wrapper{
      .tab-content{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        height: 24px;
        .tab-icon{
          background-color: var(--colorIconSecondary);
          width: 24px;
          height: 24px;
        }
      }
    }
    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.024px;
    }
    .line {
      margin-top: 4px;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: transparent;
      transition: background-color 0.2s ease;
    }
  }
  &.stretched{
    width: 100%;
    .tab {
      flex: 1 0 0;
    }
  }
  &.block-scroll-tabs{
    overflow:visible;
  }
}

@media screen and (max-width: 743px){
  .tabs-container {
    .tab {
      padding: 2px 8px;
      .tab-content-wrapper{
        .tab-content{
          gap: 2px;
          height: 20px;
          .tab-icon{
            width: 20px;
            height: 20px;
          }
        }
      }
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.07px;
      }
    }
  }
}
</style>