<template>
  <teleport to="body">
    <div class="screen-spinner">
      <div class="screen-spinner-window" @click="$emit('close-screen-spinner')">
        <div class="spinner">
          <svg class="screen-spinner-close" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.85 12L4 11.15L7.15 8L4 4.85L4.85 4L8 7.15L11.15 4L12 4.85L8.85 8L12 11.15L11.15 12L8 8.85L4.85 12Z" fill="var(--colorComponentSpinnerBuffer)"/>
          </svg>
          <div class="spinner-border">
            <span class="sr-only">{{ $t('загрузка') }}</span>
          </div>
        </div>
        <h6>
          <slot/>
        </h6>
      </div>
    </div>
  </teleport>
</template>

<script>
import Teleport from "@/components/redesign/teleport.vue";

export default {
  name: "ScreenSpinner",
  components: {Teleport},
}
</script>

<style scoped lang="scss">
.screen-spinner {
  z-index: 9000000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--colorOverlay);
  display: flex;
  align-items: center;
  justify-content: center;
  .screen-spinner-window {
    background-color: var(--colorComponentDropdownListItemBackground);
    max-width: 292px;
    width: 100%;
    height: 170px;
    border-radius: 12px;
    padding: 24px;
    box-shadow: #17171C1A 0 16px 32px, #17171C26 0 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    position: relative;
    h6 {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: var(--colorTextSecondary);
    }
    .spinner {
      position: relative;

      .screen-spinner-close {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 50%;
        height: 50%;
      }
      .spinner-border {
        display: inline-block;
        width: 4rem;
        height: 4rem;
        vertical-align: text-bottom;
        border: 2px solid var(--colorComponentSpinnerBuffer);
        border-right-color: var(--colorComponentSpinnerBackground);
        border-radius: 50%;
        animation: 0.85s linear infinite spinner-border;
        position: relative;
        > svg {
          position: absolute;
        }
        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;
        }
      }
    }
  }

}
</style>