<template>
  <div class="indicator-tag"  :class="[type, appearance, size]">
    <div class="icon-tag" v-if="icon">
      <div class="icon-tag__container" :style="`mask: url('${icon}') no-repeat center / contain;  -webkit-mask: url('${icon}') no-repeat center / contain;`"></div>
    </div>
    <div class="tag-text" v-if="text">{{text}}</div>
  </div>
</template>

<script>
/**
 * @prop {String} icon - иконка
 * @prop {String} text - текст
 *
 * @prop {String} type - Отвечает за тип тега и имеет следующие состояния:
 * 1.contrast (дефолтное состояние)
 * 2.primary
 * 3.secondary (только при appearance = neutral)
 *
 * @prop {String} appearance - Отвечает за внешний вид тега и имеет следующие состояния:
 * 1.default-appearance (дефолтное состояние)
 * 2.negative
 * 3.neutral
 *
 * @prop {String} size - Отвечает за размер тега и имеет следующие состояния:
 * 1.large
 * 2.medium (дефолтное состояние)
 * 3.small
 */
export default {
  name: "IndicatorTag",
  props:{
    icon:{
      type:String,
      default:''
    },
    text:{
      type:String,
      default:''
    },
    type: {
      type: String,
      default: 'contrast'
    },
    appearance: {
      type: String,
      default: 'default-appearance'
    },
    size:{
      type: String,
      default: 'medium'
    },
  }
}
</script>

<style scoped lang="scss">
.indicator-tag{
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  .icon-tag{
    .icon-tag__container{
      width: 20px;
      height: 20px;
    }
  }
  .tag-text{
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;
    font-style: normal;
  }

  &.contrast{
    background: var(--colorIconAccent);
    .icon-tag{
      .icon-tag__container{
        background-color: var(--colorIconContrast);
      }
    }
    .tag-text{
      color: var(--colorTextContrast);
    }
  }
  &.primary{
    background: var(--colorIconContrastInvariably);
    .icon-tag{
      .icon-tag__container{
        background-color: var(--colorIconAccentInvariably);
      }
    }
    .tag-text{
      color: var(--colorTextAccentInvariably);
    }
  }

  &.negative{
    &.contrast{
      background: var(--colorIconNegative);
      .icon-tag{
        .icon-tag__container{
          background-color: var(--colorIconContrastInvariably);
        }
      }
      .tag-text{
        color: var(--colorTextContrastInvariably);
      }
    }
    &.primary{
      background: var(--colorIconContrastInvariably);
      .icon-tag{
        .icon-tag__container{
          background-color: var(--colorIconNegative);
        }
      }
      .tag-text{
        color: var(--colorTextNegative);
      }
    }
  }

  &.neutral{
    &.contrast{
      background: var(--colorIconPrimary);
      .icon-tag{
        .icon-tag__container{
          background-color: var(--colorIconContrast);
        }
      }
      .tag-text{
        color: var(--colorTextContrast);
      }
    }
    &.primary{
      background: var(--colorIconContrast);
      .icon-tag{
        .icon-tag__container{
          background-color: var(--colorIconPrimary);
        }
      }
      .tag-text{
        color: var(--colorTextPrimary);
      }
    }
    &.secondary{
      background: var(--colorBackgroundSecondary);
      .icon-tag{
        .icon-tag__container{
          background-color: var(--colorIconPrimary);
        }
      }
      .tag-text{
        color: var(--colorTextPrimary);
      }
    }
  }

  &.large{
    height: 28px;
    padding: 4px 12px;
    .tag-text{
      font-size: 14px;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.07px;
    }
  }
  &.medium{
    padding: 2px 8px;
    .tag-text{
      font-size: 12px;
      font-weight: 500;
      line-height: 14px; /* 116.667% */
      letter-spacing: 0.03px;
    }
    .icon-tag{
      display: none;
    }
  }
  &.small{
    padding: 0 8px;
    .tag-text{
      font-size: 8px;
      font-weight: 400;
      line-height: 12px; /* 150% */
      letter-spacing: 0.008px;
    }
    .icon-tag{
      display: none;
    }
  }

}
</style>