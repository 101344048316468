import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/locales/messages.json'

Vue.use(VueI18n)

function detectLanguage () {
  return 'ru'
  const lng = window.navigator.userLanguage || window.navigator.language
  const locales = require.context(
      './locales',
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const lang = locales
      .keys()
      .find((key) => lng.includes(key.replace('./', '').replace('.json', '')))
  // console.log(lng)
  return lang ? lang.replace('./', '').replace('.json', '') : null
}

var mesData = messages

export default new VueI18n({
  locale:
      localStorage.getItem('lang') ||
      'ru',
  fallbackLocale: 'ru',
  messages: mesData
})
