import Vue from 'vue'
import Vuex from 'vuex'
import {profileModule} from "@/store/profileModule";
import i18n from '@/i18n';

Vue.use(Vuex)
/* eslint-disable */
export default new Vuex.Store({
  state: {
    uuid: localStorage.getItem('myUuid') || 'unknown',
    recent: [],
    programs: [
      // 'Субсидирование',
      'Семейная ипотека с субсидированием',
      'Семейная ипотека без субсидирования',
      'Гос. поддержка с субсидированием',
      'Гос. поддержка без субсидирования',
      'Комбоипотека',
      'Новостройка с субсидированием',
      'Новостройка без субсидирования',
      'Вторичка',
      'Готовое жилье',
      // 'Гибрид',
      'IT',
      // 'Прочие'
    ],
    blackTheme:JSON.parse(localStorage.getItem('blackApp'))||false,
    maskPhone:'+9(999)999-99-99',
    object: {},
    profile: {},
    bookmark: {},
    firsts:JSON.parse(localStorage.getItem('myFirstsInputsStore')) || [],
    first_percents:JSON.parse(localStorage.getItem('myFirstPercentsInputsStore')) || [],
    prices:JSON.parse(localStorage.getItem('myPricesInputsStore'))|| [],
    rates:JSON.parse(localStorage.getItem('myRatesInputsStore')) || [],
    capitals:JSON.parse(localStorage.getItem('myCapitalsInputsStore')) || [],
    compl: [],
    selectedObjects:[],
    role: localStorage.getItem('myRole') || 'unknown',
    roles: JSON.parse(localStorage.getItem('myRoles')) || null,
    filter: JSON.parse(localStorage.getItem('myFiltersNew')) || {},
    filterCatalogStatic: JSON.parse(localStorage.getItem('myFilterCatalogStatic')) || {},
    calcCatalogStatic: JSON.parse(localStorage.getItem('myCalcCatalogStatic')) || {},
    filterSearchAgentAdmin: JSON.parse(localStorage.getItem('myFilterSearchAgentAdmin')) || null,
    filterBuysAdmin: JSON.parse(localStorage.getItem('myFiltersBuysAdmin')) || null,
    columnLeads:JSON.parse(localStorage.getItem('myColumnLeads'))||{},
    visibleChat:localStorage.getItem('myVisibleChat')||true,
    visibleBetaNotification:localStorage.getItem('myVisibleBeta')||true,
    // visibleInstallParam:localStorage.getItem('visibleInstall')||'true',
    visibleModalBoard:false,
    visibleFilterCatalog:true,
    messages: {},
    message:{},
    newsData:null,
    filterLeads: JSON.parse(localStorage.getItem('myFilterLeads')) || {},
    chatPort:'4444',
    goConnect:'https://story.itrielt.ru',
    selectedSortCatalog: JSON.parse(localStorage.getItem('mySelectedSortCatalog')) ?? i18n.t('ПоВозрастаниюЦены'),
    currentDomain: null,

    filterVipMain: JSON.parse(localStorage.getItem('myFilterVipMain')) || {},
    filterVipCalc: JSON.parse(localStorage.getItem('myFilterVipCalc')) || {},
    voiceAssistantContext: {},
    statusesBooked:['Бронь','В корзине','Сделка в работе','Забронировано', 'Оформление', 'Предварительная бронь', 'Бесплатная бронь','ЮСИ_Аренда'],
    statusesSales:['Договор','Продано','Передано','Снято с реализации','Не для продажи','Сделка проведена', 'ЮСИ_Бартер', 'ЮСИ_ОжидаемСтартПродаж'],
    statusesNone:['empty','Не выбрано', null, '', 'Заблокировано'],
    statusesReserved:['Резерв'],
    lastOpenedBlocTab: 'Ремонты',
    currency: {unit:'₽', description:'RUB'},
    fromBitrixDealId: null,
    agentListFilter: null,

    selectedType: JSON.parse(localStorage.getItem('mySelectedType')) || null,
    selectedTypeSale: JSON.parse(localStorage.getItem('mySelectedTypeSale')) || null,
    selectedCity: JSON.parse(localStorage.getItem('mySelectedCity')) || null,
    selectedLiter: JSON.parse(localStorage.getItem('mySelectedLiter')) || null,
    selectedJK: JSON.parse(localStorage.getItem('mySelectedJK')) || null,

    filterDefault: {
      type_object:'Квартиры',
      type_sale:[],
      order:{key:'Price',type:'ASC'},
      room: [],
      Price: {
        min: null,
        max: null
      },
      Square: {
        min: null,
        max: null
      },
      LivingSpace: {
        min: null,
        max: null
      },
      floor:{
        min: null,
        max: null
      },
      districts: [],
      objects: [],
      liters: [],
      address: null,
      class: [],
      ready: [],
      ViewFromWindows: [],
      status:['Свободно'],
      entrance:[],
      type:[],
      cities:[],
      Rooms:[],
      number:null,
      fullFilterVisible: false,
      calculator:{
        first:0,
        month_pay:0,
        time:30,
        bpv:['Нет'],
      }
    },

    responsibleList: JSON.parse(localStorage.getItem('myResponsibleList')) || [],
    navigation: {}
  },
  getters: {
    getPriceArray:state => state.priceArray,
    capitals: state => state.capitals,
    first_percents: state => state.first_percents,
    firsts: state => state.firsts,
    prices: state => state.prices,
    rates: state => state.rates,
    getBlackTheme: state => state.blackTheme,
    getMaskPhone: state => state.maskPhone,
    getUid: state => state.uuid,
    getPrograms:state => state.programs,
    getObject: state => state.object,
    getBookmark: state => state.bookmark,
    getRecent: state => state.recent,
    getCompl: state => state.compl,
    getSelectedObjects: state => state.selectedObjects,
    getRole: state => state.role,
    getRoles: state => state.roles,
    getProfile: state => state.profile,
    getFilter: state => state.filter,
    getFilterCatalogStatic: state => state.filterCatalogStatic,
    getCalcCatalogStatic: state => state.calcCatalogStatic,
    getColumnLeads: state => state.columnLeads,
    getVisibleModalBoard: state => state.visibleModalBoard,
    getVisibleFilterCatalog: state => state.visibleFilterCatalog,
    getMessages: state => state.messages,
    getMessage: state => state.message,
    getFilterLeads: state => state.filterLeads,
    getVisibleChat: state => state.visibleChat,
    getVisibleBetaNotification: state => state.visibleBetaNotification,
    // getVisibleInstall: state => state.visibleInstallParam,
    getFilterBuysAdmin: state => state.filterBuysAdmin,
    getFilterSearchAgentAdmin: state => state.filterSearchAgentAdmin,
    getNewsData: state => state.newsData,
    getChatPort: state => state.chatPort,
    getGoConnect: state => state.goConnect,
    getSelectedSortCatalog: state => state.selectedSortCatalog,
    getCurrentDomain: state => state.currentDomain,

    getFilterVipMain: state => state.filterVipMain,
    getFilterVipCalc: state => state.filterVipCalc,
    getVoiceAssistantContext: state => state.voiceAssistantContext,

    getStatusesBooked:state => state.statusesBooked,
    getStatusesReserved:state => state.statusesReserved,
    getStatusesSales:state => state.statusesSales,
    getStatusesNone:state => state.statusesNone,
    getLastOpenedBlocTab:state => state.lastOpenedBlocTab,
    getCurrency:state => state.currency,
    getFromBitrixDealId: state => state.fromBitrixDealId,
    getAgentListFilter: state => state.agentListFilter,

    getSelectedType: state => state.selectedType,
    getSelectedTypeSale: state => state.selectedTypeSale,
    getSelectedCity: state => state.selectedCity,
    getSelectedLiter: state => state.selectedLiter,
    getSelectedJK: state => state.selectedJK,

    getFilterDefault: state => state.filterDefault,

    getResponsibleList: state => state.responsibleList,
    getNavigation: state => state.navigation
  },
  mutations: {
    SET_PRICE_ARRAY: (state,resp) => {
      Vue.set(state,'priceArray',resp)
    },
    SET_BLACK_THEME: (state, resp) => {
      Vue.set(state, 'blackTheme', resp)
      localStorage.setItem('blackApp', JSON.stringify(resp))
    },
    SET_BLACK_THEME_WITHOUT_STORE: (state, resp) => {
      Vue.set(state, 'blackTheme', resp)
    },
    SET_MASK_PHONE: (state, resp) => {
      Vue.set(state, 'maskPhone', resp)
    },
    SET_GO_CONNECT: (state, resp) => {
      Vue.set(state, 'goConnect', resp)
    },
    SET_CHAT_PORT: (state, resp) => {
      Vue.set(state, 'chatPort', resp)
    },
    SET_SELECTED_SORT_CATALOG: (state, resp) => {
      Vue.set(state, 'selectedSortCatalog', resp)
      localStorage.setItem('mySelectedSortCatalog', JSON.stringify(resp))
    },
    SET_NEWS_DATA: (state, resp) => {
      Vue.set(state, 'newsData', resp)
    },
    SET_FILTER_BUYS_ADMIN: (state, resp) => {
      Vue.set(state, 'filterBuysAdmin', resp)
      localStorage.setItem('myFiltersBuysAdmin', JSON.stringify(resp))
    },
    SET_FILTER_SEARCH_AGENT_ADMIN: (state, resp) => {
      Vue.set(state, 'filterSearchAgentAdmin', resp)
      localStorage.setItem('myFilterSearchAgentAdmin', JSON.stringify(resp))
    },
    /*SET_VISIBLE_INSTALL: (state, resp) => {
      Vue.set(state, 'visibleInstallParam', resp)
      localStorage.setItem('visibleInstall', JSON.stringify(resp))
    },*/
    SET_VISIBLE_BETA_NOTIFICATION: (state, resp) => {
      Vue.set(state, 'visibleBetaNotification', resp)
      localStorage.setItem('myVisibleBeta', JSON.stringify(resp))
    },
    SET_FILTER_LEADS: (state, resp) => {
      Vue.set(state, 'filterLeads', resp)
      localStorage.setItem('myFilterLeads', JSON.stringify(resp))
    },
    SET_VISIBLE_CHAT: (state, resp) => {
      Vue.set(state, 'visibleChat', resp)
      localStorage.setItem('myVisibleChat', JSON.stringify(resp))
    },
    SET_MESSAGE: (state, resp) => {
      Vue.set(state, 'message', resp)
    },
    SET_MESSAGES: (state, resp) => {
      Vue.set(state, 'messages', resp)
    },
    SET_VISIBLE_MODAL_BOARD: (state, resp) => {
      Vue.set(state, 'visibleModalBoard', resp)
    },
    SET_VISIBLE_FILTER_CATALOG: (state, resp) => {
      Vue.set(state, 'visibleFilterCatalog', resp)
    },
    SET_COLUMN_LEADS: (state, resp) => {
      Vue.set(state, 'columnLeads', resp)
      localStorage.setItem('myColumnLeads', JSON.stringify(resp))
    },
    SET_FILTER: (state, resp) => {
      Vue.set(state, 'filtersNew', resp)
      localStorage.setItem('myFiltersNew', JSON.stringify(resp))
    },
    SET_FILTER_CATALOG_STATIC: (state, resp) => {
      Vue.set(state, 'filterCatalogStatic', resp)
      localStorage.setItem('myFilterCatalogStatic', JSON.stringify(resp))
    },
    SET_CALC_CATALOG_STATIC: (state, resp) => {
      Vue.set(state, 'calcCatalogStatic', resp)
      localStorage.setItem('myCalcCatalogStatic', JSON.stringify(resp))
    },
    SET_OBJECT: (state, resp) => {
      Vue.set(state, 'object', resp)
    },
    SET_PROFILE: (state, resp) => {
      Vue.set(state, 'profile', resp)
    },
    SET_BOOKMARK: (state, resp) => {
      Vue.set(state, 'bookmark', resp)
    },

    SET_RECENT_ARRAY: (state, resp) => {
      Vue.set(state, 'recent', resp)
    },
    SET_COMPL: (state, resp) => {
      Vue.set(state, 'compl', resp)
    },
    SET_SELECTED_OBJECTS_ALL: (state, resp) => {
      Vue.set(state, 'selectedObjects', resp)
    },
    SET_SELECTED_OBJECTS_SINGLE: (state, obj) => {
      Vue.set(state, 'selectedObjects', [...state.selectedObjects, ...obj ])
    },
    SET_ROLE: (state, resp) => {
      Vue.set(state, 'role', resp)
      localStorage.setItem('myRole', resp)
    },
    SET_ROLES: (state, resp) => {
      Vue.set(state, 'roles', resp)
      localStorage.setItem('myRoles', JSON.stringify(resp))
    },
    SET_UUID: (state, resp) => {
      Vue.set(state, 'uuid', resp)
      localStorage.setItem('myUuid', resp)
    },
    SET_FIRST_PERCENTS: (state, resp) => {
      var array = state.first_percents
      if(!array.includes(resp)){
        if(array.length===2){
          delete array[0]
          array=array.filter(el=>el)
        }
        array.push(resp)
      }
      Vue.set(state, 'first_percents', array)
      localStorage.setItem('myFirstPercentsInputsStore', JSON.stringify(array))
    },
    SET_FIRSTS: (state, resp) => {
      var array = state.firsts
      if(!array.includes(resp)){
        if(array.length===3){
          delete array[0]
          array=array.filter(el=>el)
        }
        array.push(resp)
      }
      Vue.set(state, 'firsts', array)
      localStorage.setItem('myFirstsInputsStore', JSON.stringify(array))
    },
    SET_RATES: (state, resp) => {
      var array = state.rates
      if(!array.includes(resp)){
        if(array.length===3){
          delete array[0]
          array=array.filter(el=>el)
        }
        array.push(resp)
      }
      Vue.set(state, 'rates', array)
      localStorage.setItem('myRatesInputsStore', JSON.stringify(array))
    },
    SET_PRICES: (state, resp) => {
      var array = state.prices
      if(!array.includes(resp)){
        if(array.length===3){
          delete array[0]
          array=array.filter(el=>el)
        }
        array.push(resp)
      }
      Vue.set(state, 'prices', array)
      localStorage.setItem('myPricesInputsStore', JSON.stringify(array))
    },
    SET_CAPITALS: (state, resp) => {
      var array = state.capitals
      if(!array.includes(resp)){
        if(array.length===3){
          delete array[0]
          array=array.filter(el=>el)
        }
        array.push(resp)
      }
      Vue.set(state, 'capitals', array)
      localStorage.setItem('myCapitalsInputsStore', JSON.stringify(array))
    },
    SET_RECENT_LIST: (state, resp) => {
      Vue.set(state, 'recent', resp)
    },
    SET_RECENT: (state, resp) => {
      var recent = state.recent
      if(recent.indexOf(resp) !== -1) delete recent[recent.indexOf(resp)]
      else recent.push(resp)
      recent = recent.filter(function(n){return n !== undefined});
      Vue.set(state, 'recent', recent)
      localStorage.setItem('recent_list', JSON.stringify(recent))
    },
    SET_CURRENT_DOMAIN: (state, value) => {
      Vue.set(state, "currentDomain", value)
    },


    SET_FILTER_VIP_MAIN: (state, resp) => {
      Vue.set(state, 'filterVipMain', resp)
      localStorage.setItem('myFilterVipMain', JSON.stringify(resp))
    },
    SET_FILTER_VIP_CALC: (state, resp) => {
      Vue.set(state, 'filterVipCalc', resp)
      localStorage.setItem('myFilterVipCalc', JSON.stringify(resp))
    },
    SET_VOICE_ASSISTANT_CONTEXT: (state, resp) => {
      Vue.set(state, 'voiceAssistantContext', resp)
    },
    SET_LAST_OPENED_BLOC_TAB: (state, resp) => {
      Vue.set(state, 'lastOpenedBlocTab', resp)
    },
    SET_CURRENCY: (state, resp) => {
      Vue.set(state, 'currency', resp)
    },
    SET_FROM_BITRIX_DEAL_ID: (state, resp) => {
      Vue.set(state, 'fromBitrixDealId', resp)
    },

    SET_STATUSES_BOOKED: (state, resp) => {
      Vue.set(state, 'statusesBooked', resp)
    },
    SET_STATUSES_RESERVED: (state, resp) => {
      Vue.set(state, 'statusesReserved', resp)
    },
    SET_STATUSES_SALES: (state, resp) => {
      Vue.set(state, 'statusesSales', resp)
    },
    SET_STATUSES_NONE: (state, resp) => {
      Vue.set(state, 'statusesNone', resp)
    },
    SET_AGENT_LIST_FILTER: (state, resp) => {
      Vue.set(state, 'agentListFilter', resp)
    },
    SET_MY_SELECTED_TYPE_SALE: (state, resp) => {
      Vue.set(state, 'selectedTypeSale', resp)
      localStorage.setItem('mySelectedTypeSale', JSON.stringify(resp))
    },
    SET_MY_SELECTED_TYPE: (state, resp) => {
      Vue.set(state, 'selectedType', resp)
      localStorage.setItem('mySelectedType', JSON.stringify(resp))
    },
    SET_MY_SELECTED_CITY: (state, resp) => {
      Vue.set(state, 'selectedCity', resp)
      localStorage.setItem('mySelectedCity', JSON.stringify(resp))
    },
    SET_MY_SELECTED_JK: (state, resp) => {
      Vue.set(state, 'selectedJK', resp)
      localStorage.setItem('mySelectedJK', JSON.stringify(resp))
    },
    SET_MY_SELECTED_LITER: (state, resp) => {
      Vue.set(state, 'selectedLiter', resp)
      localStorage.setItem('mySelectedLiter', JSON.stringify(resp))
    },

    SET_MY_RESPONSIBLE_LIST: (state, resp) => {
      Vue.set(state, 'responsibleList', resp)
      localStorage.setItem('myResponsibleList', JSON.stringify(resp))
    },
    SET_NAVIGATION: (state, object) => {
      Vue.set(state, 'navigation', object)
    }
  },
  actions: {
    SET_PRICE_ARRAY:({commit,dispatch,getters},array) => {
      commit('SET_PRICE_ARRAY',array)
    },
    SET_MASK_PHONE: ({ commit, dispatch, getters }, string) => {
      commit('SET_MASK_PHONE', string)
    },
    SET_GO_CONNECT: ({ commit, dispatch, getters }, string) => {
      commit('SET_GO_CONNECT', string)
    },
    SET_CHAT_PORT: ({ commit, dispatch, getters }, string) => {
      commit('SET_CHAT_PORT', string)
    },
    SET_NEWS_DATA: ({ commit, dispatch, getters }, object) => {
      commit('SET_NEWS_DATA', object)
    },
    SET_VISIBLE_BETA_NOTIFICATION: ({ commit, dispatch, getters }, object) => {
      commit('SET_VISIBLE_BETA_NOTIFICATION', object)
    },
    SET_VISIBLE_CHAT: ({ commit, dispatch, getters }, object) => {
      commit('SET_VISIBLE_CHAT', object)
    },
    SET_FILTER_BUYS_ADMIN: ({ commit, dispatch, getters }, object) => {
      commit('SET_FILTER_BUYS_ADMIN', object)
    },
    SET_FILTER_SEARCH_AGENT_ADMIN: ({ commit, dispatch, getters }, object) => {
      commit('SET_FILTER_SEARCH_AGENT_ADMIN', object)
    },
    SET_FILTER_LEADS: ({ commit, dispatch, getters }, object) => {
      commit('SET_FILTER_LEADS', object)
    },
    SET_MESSAGE: ({ commit, dispatch, getters }, object) => {
      commit('SET_MESSAGE', object)
    },
    SET_MESSAGES: ({ commit, dispatch, getters }, object) => {
      commit('SET_MESSAGES', object)
    },
    SET_OBJECT: ({ commit, dispatch, getters }, object) => {
      commit('SET_OBJECT', object)
    },
    SET_PROFILE: ({ commit, dispatch, getters }, object) => {
      commit('SET_PROFILE', object)
    },
    SET_BOOKMARK: ({ commit, dispatch, getters }, object) => {
      commit('SET_BOOKMARK', object)
    },
    SET_RECENT: ({ commit, dispatch, getters }, id) => {
      commit('SET_RECENT', id)
    },
    SET_RECENT_LIST: ({ commit, dispatch, getters }, object) => {
      commit('SET_RECENT_LIST', object)
    },
    SET_COMPL: ({ commit, dispatch, getters }, object) => {
      commit('SET_COMPL', object)
    },
    SET_ROLE: ({ commit, dispatch, getters }, string) => {
      commit('SET_ROLE', string)
    },
    SET_ROLES: ({ commit, dispatch, getters }, array) => {
      commit('SET_ROLES', array)
    },
    SET_UUID: ({ commit, dispatch, getters }, string) => {
      commit('SET_UUID', string)
    },
    LOAD_RECENT: ({ commit, dispatch, getters }, id) => {
      var json = localStorage.getItem('recent_list')
      if(json) {
        json = JSON.parse(json)
        if (Object.keys(json).length > 0) commit('SET_RECENT_ARRAY', json)
      }
    },
    SET_CURRENT_DOMAIN: ({ commit, dispatch, getters }, string) => {
      commit('SET_CURRENT_DOMAIN', string)
    },
    SET_VOICE_ASSISTANT_CONTEXT: ({ commit, dispatch, getters }, object) => {
      commit('SET_VOICE_ASSISTANT_CONTEXT', object)
    },
    SET_LAST_OPENED_BLOC_TAB: ({ commit, dispatch, getters }, object) => {
      commit('SET_LAST_OPENED_BLOC_TAB', object)
    },
    SET_CURRENCY: ({ commit, dispatch, getters }, object) => {
      commit('SET_CURRENCY', object)
    },
    SET_FROM_BITRIX_DEAL_ID: ({ commit, dispatch, getters }, id) => {
      commit('SET_FROM_BITRIX_DEAL_ID', id)
    },

    SET_STATUSES_BOOKED: ({ commit, dispatch, getters }, bool) => {
      commit('SET_STATUSES_BOOKED', bool)
    },
    SET_STATUSES_RESERVED: ({ commit, dispatch, getters }, bool) => {
      commit('SET_STATUSES_RESERVED', bool)
    },
    SET_STATUSES_SALES: ({ commit, dispatch, getters }, bool) => {
      commit('SET_STATUSES_SALES', bool)
    },
    SET_STATUSES_NONE: ({ commit, dispatch, getters }, bool) => {
      commit('SET_STATUSES_NONE', bool)
    },
    SET_AGENT_LIST_FILTER: ({ commit }, filter) => {
      commit('SET_AGENT_LIST_FILTER', filter)
    },
    SET_NAVIGATION: ({commit}, object) => {
      commit('SET_NAVIGATION', object)
    }
  },
  modules: {
    profileData:profileModule
  }
})
